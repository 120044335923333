import React from "react";
import { FormLayout } from "react-vaadin-components";

import Weekday from "./weekday";

function OpeningHours({ weekdays, onChange, readOnly }) {
  const updateData = (d) => {
    onChange({ ...weekdays, ...d });
  };

  return (
    <FormLayout
      responsiveSteps={[
        { minWidth: 0, columns: 1 },
        { minWidth: "35em", columns: 6 },
      ]}
    >
      <Weekday
        day="monday"
        readOnly={readOnly}
        openingHours={{
          isOpen: weekdays.mondayIsOpen,
          opens: weekdays.mondayOpens,
          closes: weekdays.mondayCloses,
        }}
        onChange={(data) => {
          updateData({
            mondayIsOpen: data.isOpen,
            mondayOpens: data.opens,
            mondayCloses: data.closes,
          });
        }}
      />
      <Weekday
        day="tuesday"
        readOnly={readOnly}
        openingHours={{
          isOpen: weekdays.tuesdayIsOpen,
          opens: weekdays.tuesdayOpens,
          closes: weekdays.tuesdayCloses,
        }}
        onChange={(data) => {
          updateData({
            tuesdayIsOpen: data.isOpen,
            tuesdayOpens: data.opens,
            tuesdayCloses: data.closes,
          });
        }}
      />
      <Weekday
        day="wednesday"
        readOnly={readOnly}
        openingHours={{
          isOpen: weekdays.wednesdayIsOpen,
          opens: weekdays.wednesdayOpens,
          closes: weekdays.wednesdayCloses,
        }}
        onChange={(data) => {
          updateData({
            wednesdayIsOpen: data.isOpen,
            wednesdayOpens: data.opens,
            wednesdayCloses: data.closes,
          });
        }}
      />
      <Weekday
        day="thursday"
        readOnly={readOnly}
        openingHours={{
          isOpen: weekdays.thursdayIsOpen,
          opens: weekdays.thursdayOpens,
          closes: weekdays.thursdayCloses,
        }}
        onChange={(data) => {
          updateData({
            thursdayIsOpen: data.isOpen,
            thursdayOpens: data.opens,
            thursdayCloses: data.closes,
          });
        }}
      />
      <Weekday
        day="friday"
        readOnly={readOnly}
        openingHours={{
          isOpen: weekdays.fridayIsOpen,
          opens: weekdays.fridayOpens,
          closes: weekdays.fridayCloses,
        }}
        onChange={(data) => {
          updateData({
            fridayIsOpen: data.isOpen,
            fridayOpens: data.opens,
            fridayCloses: data.closes,
          });
        }}
      />
      <Weekday
        day="saturday"
        readOnly={readOnly}
        openingHours={{
          isOpen: weekdays.saturdayIsOpen,
          opens: weekdays.saturdayOpens,
          closes: weekdays.saturdayCloses,
        }}
        onChange={(data) => {
          updateData({
            saturdayIsOpen: data.isOpen,
            saturdayOpens: data.opens,
            saturdayCloses: data.closes,
          });
        }}
      />
      <Weekday
        day="sunday"
        readOnly={readOnly}
        openingHours={{
          isOpen: weekdays.sundayIsOpen,
          opens: weekdays.sundayOpens,
          closes: weekdays.sundayCloses,
        }}
        onChange={(data) => {
          updateData({
            sundayIsOpen: data.isOpen,
            sundayOpens: data.opens,
            sundayCloses: data.closes,
          });
        }}
      />
    </FormLayout>
  );
}

export default OpeningHours;
