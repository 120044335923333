import {
  registerStyles,
  css,
} from "@vaadin/vaadin-themable-mixin/register-styles.js";

export default registerStyles(
  "vaadin-tab",
  css`
    // :host ::slotted(a) {
    //   color: red !important;
    //   background: red;
    // }
    :host ::slotted(a.active) {
      color: #9b0ae3 !important /* use primary color */ ;
      font-weight: 600;
    }
    :host([focus-ring]) {
      /* Remove the vaadin tab focus-ring! */
      border: 1px solid red;
      border-radius: 0;
      border: none;
      box-shadow: none;
    }
  `
);
