import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid, GridColumn } from "react-vaadin-components";
import Icons from "@vaadin/vaadin-icons";
import { Ability } from "@casl/ability";

import { useConfirmService } from "../../services/confirm";
import ErrorHandler from "../../components/ErrorHandling";
import { formatDate } from "../../helpers/Functions";

function EditItem(id) {
  //@todo I am a placeholder for edit function!
  //alert("Start editing item with id " + id);
}

function RendererShopType({ item }) {
  const list = item.shop_type_access;
  const displayNames = list.map((list) => list.displayName).join(", ");
  return displayNames; // return the displayName items with an comma from the users shop_type_access list
}

function RenderUserEnabled({ item }) {
  const userNotActive = item.blocked;
  let icon = (
    <iron-icon
      id="icon-error"
      class="sis-icon-user--disabled"
      icon="vaadin:close-circle"
    />
  );

  if (!userNotActive) {
    icon = (
      <iron-icon
        id="icon-success"
        class="sis-icon-user--actived"
        icon="vaadin:check-circle"
      />
    );
  }
  return icon;
}

function UserList({ users, me, onOpen, onDelete, accessRules, t }) {
  const history = useHistory();
  const grid = useRef();
  const confirm = useConfirmService();

  const ability = new Ability(accessRules);

  function RenderDate({ item }) {
    const newDateTime =
      item.lastLoginAt === null
        ? t("error.invalid.datetime")
        : formatDate(item.lastLoginAt, "dd.MM.yyyy HH:mm");
    return newDateTime;
  }

  function EditBtn(onDelete) {
    return ({ item }) => {
      const deleteAbility = ability.can("delete", "User");
      const editAbility = ability.can("update", "User");

      return (
        <>
          {editAbility ? (
            <iron-icon
              id="icon-id-edit"
              className="sis-icon-edit"
              value={item.id}
              onClick={() => onOpen(item)}
              icon="vaadin:pencil"
            />
          ) : null}
          {deleteAbility ? (
            <iron-icon
              id="icon-id-delete"
              className="sis-icon-delete"
              onClick={() => onDelete(item)}
              icon="vaadin:trash"
            />
          ) : null}
        </>
      );
    };

    if (users.length <= 0) {
      return (
        <ErrorHandler
          errorTitle={t("error.title.noUsersFoundInListTitle")}
          error={t("error.message.noUsersFoundInList")}
        />
      );
    }
  }

  return (
    <Grid
      className="sis-container"
      items={users}
      heightByRows={true}
      ref={grid}
      onClick={(e) => {
        if (e.target.icon !== undefined) {
          // Let's ignore icons (e.g. edit / delete)
          return;
        }
        const { item } = grid.current._element.getEventContext(e);
        if (item) history.push(`/users/${item.id}`);
      }}
    >
      <GridColumn
        path="username"
        header={t("user.field.username")}
        resizable
        width="15em"
      />
      <GridColumn path="role.name" header={t("user.field.role")} />
      <GridColumn
        path="shop_type_access"
        header={t("user.field.shoptypeaccess")}
        renderer={RendererShopType}
        resizable
      />
      <GridColumn
        path="blocked"
        header={t("user.field.accountdisabled")}
        renderer={RenderUserEnabled}
        textAlign="center"
      />
      <GridColumn
        path="lastLoginAt"
        header={t("user.field.lastlogin")}
        renderer={RenderDate}
        resizable
        width="10em"
      />

      {me.role.type === "administrator" ? (
        <GridColumn
          path=""
          header={t("user.field.editheader")}
          id="editUserBtn"
          renderer={EditBtn((item) => {
            confirm.show({
              buttonOk: t("common.button.ok"),
              buttonCancel: t("common.button.cancel"),
              onOk: () => {
                onDelete(item);
              },
              content: (
                <>
                  <p>{t("user.delete.confirm.msg")}</p>
                  <h4>{item.username}</h4>
                </>
              ),
            });
          })}
        />
      ) : null}
    </Grid>
  );
}

export default withTranslation()(UserList);
