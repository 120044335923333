import {
  debounce,
  set,
  unset,
  orderBy,
  map,
  without,
  forEach,
  filter as lodashFilter,
  isEmpty,
} from "lodash";
import { concat, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { VerticalLayout } from "react-vaadin-components";
import { withTranslation } from "react-i18next";
import React, { useState } from "react";

import { useNotificationService } from "../../services/notification";
import ErrorHandler from "../../components/ErrorHandling";
import Heading from "../../components/Heading";
import Query from "../../components/Query";
import searchQuery, { formatSearchShopQuery } from "./queries/search-query";
import deleteQuery from "./queries/delete-query";
import ShopList from "../../components/ShopList";
import ShopSearchFilter from "../../components/ShopSearchFilter";
import { isNonEmptyArray } from "@apollo/client/utilities";

function Shops({ t }) {
  const history = useHistory();
  const notifications = useNotificationService();
  const [filter, setFilter] = useState({});
  const [typeInUse, setTypeInUse] = useState(false); // to check if shop type filter is in use
  const [serviceInUse, setServiceInUse] = useState(false);
  const [servicesSelected, setServicesSelected] = useState([]); // use this for now, until strapi makes nested filtering possible...
  let servicesSelectedArray = []; // use this for now, until strapi makes nested filtering possible...

  const [executeDelete] = useMutation(deleteQuery, {
    onCompleted: () => {
      notifications.success({ content: <p>{t("shop.delete.success.msg")}</p> });
    },
    onError: () => {
      notifications.error({
        content: (
          <p>
            <b>{t("common.error.title")}</b>
            <br />
            {t("shop.delete.error.msg")}
          </p>
        ),
      });
    },
  });

  const onDelete = async (id) => {
    await executeDelete({
      variables: { id },
      refetchQueries: ["Shops"],
    });
  };

  const updateFilter = debounce((path, value) => {
    // create copy
    const newFilter = { ...filter };

    if (value !== null) {
      // update
      set(newFilter, path, value);

      if (path === "type_in") setTypeInUse(true);
      if (path === "services_in") setServiceInUse(true);
    } else {
      if (path === "type_in") {
        if (value === null) {
          setTypeInUse(false);
        }
      }
      if (path === "services_in") {
        if (value === null) {
          setServiceInUse(false);
        }
      }

      // remove
      unset(newFilter, path);
    }

    // a way to reset the filter (when multiple paths)
    if (path === "reset") {
      setFilter({});
      return;
    }

    // update state
    setFilter(newFilter);
  }, 250);

  // create
  const actions = {
    create: {
      text: t("common.button.create"),
      // disabled: !mutated || loading,
      loading: false,
      theme: "success primary",
      title: "",
      callback: () => {
        history.push("/shops/create");
      },
    },
  };

  const filterQuery = gql`
    query FilterQuery {
      shopTypes {
        id
        displayName
      }
      serviceTypes {
        id
        displayName
      }
    }
  `;

  return (
    <Query query={filterQuery}>
      {({ data: { shopTypes, serviceTypes } }) => {
        const options = { shopTypes, serviceTypes };

        return (
          <VerticalLayout>
            <Heading
              title={t("shop.list.title")}
              actions={actions}
              options={{ className: "sis-details--heading" }}
            />
            <ShopSearchFilter
              filter={filter}
              onChange={updateFilter}
              options={options}
              selectedServices={servicesSelected}
            />
            <Query
              query={searchQuery}
              variables={{ filter: formatSearchShopQuery(filter) }}
              fetchPolicy="network-only"
            >
              {({ data: { shops, shopsConnection, shopAccessRules } }) => {
                function filterService(service, list) {
                  return lodashFilter(list, function (element) {
                    let isMatch = false;
                    forEach(element.services, function (entry) {
                      for (let i = 0; i < service.length; i++) {
                        if (entry.type.id === service[i]) {
                          if (entry.published === true) {
                            isMatch = true;
                            return false;
                          }
                        }
                      }
                    });
                    return isMatch;
                  });
                }

                if (!isEmpty(filter.services_in)) {
                  shops = filterService(filter.services_in, shops);
                }

                if (Object.keys(shops).length <= 0) {
                  return (
                    <ErrorHandler
                      errorTitle={t("error.title.noShopsFoundInListTitle")}
                      error={t("error.message.noShopsFoundInList")}
                    />
                  );
                }

                return (
                  <ShopList
                    shops={shops}
                    onDelete={(item) => onDelete(item.id)}
                    onOpen={(item) => history.push(`/shops/${item.id}`)}
                    accessRules={shopAccessRules}
                    options={{
                      counts: !shopsConnection
                        ? null
                        : {
                            current: shopsConnection.aggregate.count,
                            total: shopsConnection.aggregate.totalCount,
                            typeInUse: typeInUse,
                            serviceInUse: serviceInUse,
                            serviceCount: shops.length,
                          },
                    }}
                  />
                );
              }}
            </Query>
          </VerticalLayout>
        );
      }}
    </Query>
  );
}

export default withTranslation()(Shops);
