import { gql } from "@apollo/client";

export default gql`
  query CreateShop($shopType: ID) {
    shopTypes {
      id
      name
      displayName
    }
    shopAccessRules(shopType: $shopType) {
      action
      subject
      fields
      conditions {
        type
      }
      inverted
    }
  }
`;
