import React from "react";
import { VerticalLayout, Button } from "react-vaadin-components";
import { cloneDeep } from "lodash";
import { withTranslation } from "react-i18next";

import Exception from "./exception";

function OpeningHourExceptions({ exceptions, onChange, readOnly, t }) {
  const except = exceptions ? cloneDeep(exceptions) : [];

  return (
    <VerticalLayout className="sis-oh-exceptions">
      <h4 className="sis-container--inner__title">
        {t("openinghours.exceptions.title")}
      </h4>
      <div className="sis-container--inner sis-container--fullsize">
        <VerticalLayout className="sis-v-layout--space-between">
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            {except.length ? (
              except.map((e, i) => (
                <Exception
                  key={"sis-oh-exception-" + i}
                  exception={e}
                  readOnly={readOnly}
                  onChange={(v) => {
                    except[i] = v;
                    onChange(except);
                  }}
                  onDelete={(v) => {
                    delete except.splice(i, 1);
                    onChange(except);
                  }}
                />
              ))
            ) : (
              <h3 className="sis-infotext__no-results sis-abs-centered">
                {t("openinghours.exceptions.no-exceptions")}
              </h3>
            )}
          </div>
          {readOnly ? null : (
            <Button
              onClick={() => {
                except.push({ opens: null, closes: null, isOpen: false });
                onChange(except);
              }}
              className="sis-oh-exceptions__create-button"
            >
              {t("openinghours.exceptions.add")}
            </Button>
          )}
        </VerticalLayout>
      </div>
    </VerticalLayout>
  );
}

export default withTranslation()(OpeningHourExceptions);
