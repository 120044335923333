import React, { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";

import Upload from "../Upload";

const Image = function ({ image, updateImage, readOnly, t }) {
  const [img, setImage] = useState(image);

  // Update the image from the parent
  useEffect(
    function () {
      setImage(image);
    },
    [image]
  );

  const mainClass = ["sis-image"];

  if (img) mainClass.push("sis-image--has-image");

  if (readOnly && !img)
    return (
      <div className="sis-image sis-image__image--no-image">
        <h3 className="sis-image__image--no-image__text">
          {t("common.image.no-image")}
        </h3>
      </div>
    );

  return (
    <div className={mainClass.join(" ")}>
      {!img ? (
        <Upload
          className="sis-image__upload"
          image={img}
          onChange={(value) => {
            updateImage(value);
          }}
        />
      ) : (
        <div
          className="sis-image__image"
          title={img.alternativeText}
          style={{
            backgroundImage:
              "url(" + process.env.REACT_APP_BACKEND_URL + img.url + ")",
          }}
        >
          {readOnly ? null : (
            <div className="sis-image__image-remove">
              <iron-icon
                icon="vaadin:close-circle"
                onClick={() => updateImage(null)}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default withTranslation()(Image);
