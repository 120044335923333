import React from "react";
import { withTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import { VerticalLayout, Button } from "react-vaadin-components";

import Service from "../../components/Service";

const ShopServices = function ({ t, services, onChange, readOnly }) {
  const servicesClone = services ? cloneDeep(services) : [];

  if (readOnly && !services.length)
    return (
      <h3 className="sis-infotext__no-results">
        {t("shop.services.no-services-available")}
      </h3>
    );

  return (
    <VerticalLayout className="sis-v-layout--space-between">
      <div style={{ width: "100%" }}>
        {servicesClone.map((service, i) => (
          <Service
            key={"sis-oh-exception-" + i}
            service={service}
            readOnly={readOnly}
            onChange={(v) => {
              servicesClone[i] = v;
              onChange(servicesClone);
            }}
            onDelete={(v) => {
              delete servicesClone.splice(i, 1);
              onChange(servicesClone);
            }}
          />
        ))}
      </div>
      {readOnly ? null : (
        <Button
          onClick={() => {
            servicesClone.push({
              type: null,
              description: null,
              image: null,
              openingHours: null,
            });
            onChange(servicesClone);
          }}
          className="sis-oh-services__create-button"
        >
          {t("shop.services.add")}
        </Button>
      )}
    </VerticalLayout>
  );
};
export default withTranslation()(ShopServices);
