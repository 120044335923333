import { gql } from "@apollo/client";
import { pick } from "lodash";

export const formatSearchShopQuery = (source) => {
  // pick generic fields
  const result = pick(source, ["name_contains", "type_in"]);

  return result;
};

export default gql`
  query Shops($filter: JSON, $limit: Int, $sort: String) {
    shops(where: $filter, limit: $limit, sort: $sort) {
      id
      name
      published
      type {
        id
        name
        displayName
      }
      location {
        street
        postalCode
        city
      }
      openingHours {
        weekdays {
          mondayIsOpen
          tuesdayIsOpen
          wednesdayIsOpen
          thursdayIsOpen
          fridayIsOpen
          saturdayIsOpen
          sundayIsOpen
        }
      }
      services {
        published
        type {
          id
          name
          displayName
        }
      }
    }
    shopsConnection(where: $filter, limit: $limit) {
      aggregate {
        count
        totalCount
      }
    }
    shopAccessRules {
      action
      subject
      fields
      conditions {
        type
      }
      inverted
    }
  }
`;
