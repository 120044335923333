import React from "react";
import { ApolloProvider } from "@apollo/client";
import { useHistory } from "react-router-dom";
import AppLayout from "../AppLayout";
import { useApolloClient } from "../../config/apolloClient";
import { notificationService } from "../../config/notifications";
import {
  NotificationProvider,
  NotificationRenderer,
} from "../../services/notification";
import { confirmService } from "../../config/confirm";
import { ConfirmProvider, ConfirmRenderer } from "../../services/confirm";

function App() {
  const client = useApolloClient();
  const history = useHistory();

  if (!history.entrypoint) {
    // store entrypoint
    history.entrypoint = history.location.key;

    // add way to check are we at the entrypoint
    history.isCurrentlyAtEntrypoint = () =>
      history.location.key === history.entrypoint;
  }

  return (
    <ApolloProvider client={client}>
      <NotificationProvider service={notificationService}>
        <ConfirmProvider service={confirmService}>
          <AppLayout />
          <ConfirmRenderer />
          <NotificationRenderer />
        </ConfirmProvider>
      </NotificationProvider>
    </ApolloProvider>
  );
}

export default App;
