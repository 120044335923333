import { gql } from "@apollo/client";

export default gql`
  fragment UserDetails on UsersPermissionsUser {
    id
    username
    email
    blocked
    shop_type_access {
      id
      name
      displayName
    }
    role {
      id
      name
    }
  }
`;
