import React, { useContext } from "react";

// TODO: do we need to store this somewhere else globally?
let context = null;

export function resetConfirmContext() {
  context = React.createContext();
}

export function getConfirmContext() {
  if (context === null) {
    resetConfirmContext();
  }
  return context;
}

export function useConfirmService() {
  return useContext(getConfirmContext());
}
