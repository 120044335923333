import { debounce, set, unset } from "lodash";
import { useHistory } from "react-router-dom";
import { VerticalLayout } from "react-vaadin-components";
import { withTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import React, { useState } from "react";

import { useNotificationService } from "../../services/notification";
import Query from "../../components/Query";
import ErrorHandler from "../../components/ErrorHandling";
import UserSearchFilter from "../../components/UserSearchFilter";
import Heading from "../../components/Heading";
import UserList from "../../components/UserList";
import searchQuery from "./queries/search-query";
import deleteQuery from "./queries/delete-query";

function Users({ t }) {
  const history = useHistory();
  const notifications = useNotificationService();
  const [filter, setFilter] = useState({});

  const updateFilter = debounce((path, value) => {
    // create copy
    const newFilter = { ...filter };

    if (value !== null) {
      // update
      set(newFilter, path, value);
    } else {
      // remove
      unset(newFilter, path);
    }

    // a way to reset the filter (when multiple paths)
    if (path === "reset") {
      setFilter({});
      return;
    }

    // update state
    setFilter(newFilter);
  }, 250);

  // create
  const actions = {
    create: {
      text: t("common.button.create"),
      // disabled: !mutated || loading,
      loading: false,
      theme: "success primary",
      title: "",
      callback: () => {
        history.push("/users/create");
      },
    },
  };

  const [executeDelete] = useMutation(deleteQuery, {
    onCompleted: () => {
      notifications.success({ content: <p>{t("shop.delete.success.msg")}</p> });
    },
    onError: () => {
      notifications.error({
        content: (
          <p>
            <b>{t("common.error.title")}</b>
            <br />
            {t("shop.delete.error.msg")}
          </p>
        ),
      });
    },
  });

  const onDelete = async (id) => {
    await executeDelete({
      variables: { id },
      refetchQueries: ["Users"],
    });
  };

  return (
    <VerticalLayout>
      <Heading
        title={t("user.list.title")}
        actions={actions}
        options={{ className: "sis-details--heading" }}
      />
      <UserSearchFilter filter={filter} onChange={updateFilter} />
      <Query
        query={searchQuery}
        variables={{ filter }}
        fetchPolicy="network-only"
      >
        {({ data: { users, me, userAccessRules } }) => {
          if (Object.keys(users).length <= 0) {
            return (
              <ErrorHandler
                errorTitle={t("error.title.noUsersFoundInListTitle")}
                error={t("error.message.noUsersFoundInList")}
              />
            );
          }
          return (
            <UserList
              users={users}
              onOpen={(item) => history.push(`/users/${item.id}`)}
              onDelete={(item) => onDelete(item.id)}
              me={me}
              accessRules={userAccessRules}
            />
          );
        }}
      </Query>
    </VerticalLayout>
  );
}

export default withTranslation()(Users);
