import React, { useRef, useEffect } from "react";

function TimePicker({ colspan, label, value, onChange, readonly }) {
  const element = useRef();

  useEffect(() => {
    if (!element.current) {
      return;
    }
    // set event listener
    const eventHandler = (event) => {
      onChange(event.target.value);
    };

    element.current.addEventListener("change", eventHandler);
    element.current.readonly = readonly;
    element.current.step = 1800;

    // eslint-disable-next-line consistent-return
    return () => {
      // clear event handler
      element.current.removeEventListener("change", eventHandler);
    };
  }, [element.current]);

  return (
    <vaadin-time-picker
      colspan={colspan}
      label={label}
      clear-button-visible
      value={value}
      ref={element}
    />
  );
}

export default TimePicker;
