import {
  registerStyles,
  css,
} from "@vaadin/vaadin-themable-mixin/register-styles.js";

export default registerStyles(
  "vaadin-upload",
  css`
    :host(.sis-image__upload) [part="primary-buttons"] {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  `
);
