import {
  registerStyles,
  css,
} from "@vaadin/vaadin-themable-mixin/register-styles.js";

export default registerStyles(
  "vaadin-checkbox",
  css`
    :host([disabled][theme~="readonly"][checked]) [part="checkbox"] {
      background-color: var(--lumo-primary-color);
    }
    :host([disabled][theme~="readonly"]) [part="checkbox"]::after {
      border-color: var(--lumo-primary-contrast-color);
    }
    :host([disabled][theme~="readonly"]) {
      color: inherit;
      opacity: 0.6;
    }
  `
);
