import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { CookiesProvider } from "react-cookie";

import App from "../App";
import "../../services/i18n";

function Root() {
  return (
    <CookiesProvider>
      <Router basename={process.env.REACT_APP_BASENAME}>
        <App />
      </Router>
    </CookiesProvider>
  );
}

export default Root;
