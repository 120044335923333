import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { VerticalLayout } from "react-vaadin-components";
import { useHistory } from "react-router-dom";
import Heading from "../../components/Heading";
import Query from "../../components/Query";
import ShopTypeSelector from "../../components/ShopTypeSelector";
import fetchQuery from "./queries/fetch-query";

function SelectShopType({ t, onSelect, backFallback }) {
  const history = useHistory();
  const [shopType, setShopType] = useState();
  const [loading, setLoading] = useState(false);
  const actions = {};

  backFallback = "/shops"; // default fallback to go back to for this.

  // go back
  actions.back = {
    text: t("common.button.back"),
    disabled: loading,
    loading,
    theme: "success primary",
    title: "",
    callback: () => {
      if (history.isCurrentlyAtEntrypoint()) {
        if (backFallback) {
          history.push(backFallback);
          return;
        }

        throw new Error("nowhere to go back to!");
      }
      history.go(-1);
    },
  };
  // next
  actions.next = {
    text: t("common.button.next"),
    disabled: !shopType || loading,
    loading,
    theme: "success primary",
    title: "",
    callback: () => {
      if (!shopType || loading) {
        return;
      }

      setLoading(true);
      onSelect(shopType);
    },
  };

  return (
    <Query query={fetchQuery}>
      {({ data: { shopTypes, shopAccessRules } }) => (
        <VerticalLayout>
          <Heading
            title={t("shop.create.title")}
            actions={actions}
            options={{ className: "sis-details--heading" }}
          />
          <h4 className="sis-container__title">
            {t("shop.create.selectShopType")}
          </h4>
          <div className="sis-container">
            <ShopTypeSelector
              shopTypes={shopTypes}
              accessRules={shopAccessRules}
              onSelect={(type) => setShopType(type)}
            />
          </div>
        </VerticalLayout>
      )}
    </Query>
  );
}

export default withTranslation()(SelectShopType);
