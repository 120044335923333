import { gql } from "@apollo/client";

export default gql`
  fragment ShopDetails on Shop {
    id
    name
    created_at
    updated_at
    type {
      id
      displayName
      name
    }
    name
    description
    image {
      id
      url
    }
    phone
    email
    url
    published
    location {
      street
      city
      postalCode
      country
      coordinatesLat
      coordinatesLng
      directions
      detailedAddress
    }
    openingHours {
      weekdays {
        mondayIsOpen
        mondayOpens
        mondayCloses
        tuesdayIsOpen
        tuesdayOpens
        tuesdayCloses
        wednesdayIsOpen
        wednesdayOpens
        wednesdayCloses
        thursdayIsOpen
        thursdayOpens
        thursdayCloses
        fridayIsOpen
        fridayOpens
        fridayCloses
        saturdayIsOpen
        saturdayOpens
        saturdayCloses
        sundayIsOpen
        sundayOpens
        sundayCloses
      }
      exceptions {
        date
        opens
        closes
        isOpen
      }
    }
    services {
      type {
        id
        name
        displayName
      }
      description
      published
      image {
        url
      }
      openingHours {
        weekdays {
          mondayIsOpen
          mondayOpens
          mondayCloses
          tuesdayIsOpen
          tuesdayOpens
          tuesdayCloses
          wednesdayIsOpen
          wednesdayOpens
          wednesdayCloses
          thursdayIsOpen
          thursdayOpens
          thursdayCloses
          fridayIsOpen
          fridayOpens
          fridayCloses
          saturdayIsOpen
          saturdayOpens
          saturdayCloses
          sundayIsOpen
          sundayOpens
          sundayCloses
        }
        exceptions {
          date
          opens
          closes
          isOpen
        }
      }
    }
  }
`;
