import React from "react";
import ReactDOM from "react-dom";

import Root from "./containers/Root";
import { i18n, LocalJsonResource } from "./services/i18n";
import Icons from "@vaadin/vaadin-icons";
import "@vaadin/vaadin";
import lang from "./lang/common.fi";
import "./scss/main.scss";
import "./styles";

const i18nResource = new LocalJsonResource(lang);
i18n.setup(i18nResource);

ReactDOM.render(<Root className="sis-root" />, document.getElementById("root"));
