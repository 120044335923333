import {
  registerStyles,
  css,
} from "@vaadin/vaadin-themable-mixin/register-styles.js";

export default registerStyles(
  "vaadin-grid",
  css`
    [part~="row"]:hover > [part~="body-cell"] {
      background: #ededed;
    }
    [part~="row"] > [part~="body-cell"][first-column] {
      // text-overflow: clip;
    }

    [part~="row"] > [part~="body-cell"][last-column] {
      // text-overflow: clip;
    }
    [part~="cell"][last-column] ::slotted(vaadin-grid-cell-content),
    [part~="cell"][first-column] ::slotted(vaadin-grid-cell-content) {
      text-overflow: clip;
    }
  `
);
