import { Ability } from "@casl/ability";
import {
  Checkbox,
  FormLayout,
  Item,
  ListBox,
  PasswordField,
  Select,
  TextField,
} from "react-vaadin-components";
import { get, isArray } from "lodash";
import { withTranslation } from "react-i18next";
import React, { useState } from "react";

import { Can, AbilityContext } from "./Can";
import { isMobile, unsavedDataWarning } from "../../helpers/Functions";

function Form({ user, accessRules, options, onChange, t }) {
  const ability = new Ability(accessRules);
  const mobileCheck = isMobile();

  // user role
  const selectedRole = get(user, "role.id", null);
  const resolveRole = (id) => options.roles.find((role) => role.id === id);
  const hiddenRoles = options.hiddenRoles || ["1", "2"];

  // resolve shop type access selected values
  const selectedShopTypeAccess = [];
  const shopTypePlaceholder = [];
  const [checkPassword, setCheckPassword] = useState(false); // if user changes password show the password confirm dialog...
  const [inputPassword, setInputPassword] = useState(null);
  const [inputPasswordCheck, setInputPasswordCheck] = useState(null);
  const [passwordLabel, setPasswordLabel] = useState(
    t("user.field.passwordConfirm.start")
  );

  // eslint-disable-next-line no-restricted-syntax
  for (const [index, shopType] of options.shopTypes.entries()) {
    if (
      isArray(user.shop_type_access) &&
      user.shop_type_access.find((type) => type.id === shopType.id)
    ) {
      // add index to selected values
      selectedShopTypeAccess.push(index);

      // add name to placeholder
      shopTypePlaceholder.push(shopType.displayName);
    }
  }

  if (!shopTypePlaceholder.length) {
    shopTypePlaceholder.push(t("form.selection.choose"));
  }

  return (
    <AbilityContext.Provider value={ability}>
      <FormLayout>
        <Can I="update" a="User" field="username" passThrough>
          {(allowed) =>
            allowed ? (
              <vaadin-email-field
                required="true"
                label={t("user.field.email")}
                name="username"
                value={user.email}
                error-message={t("user.field.email.input.error")}
                onInput={(i) => {
                  if (i.target.checkValidity()) {
                    onChange("email", i.target.value);
                  }
                }}
                clear-button-visible
              />
            ) : (
              <vaadin-email-field
                label={t("user.field.email")}
                value={user.email}
                readonly
              />
            )
          }
        </Can>
        <Can I="update" a="User" field="password" passThrough>
          {(allowed) => (
            <PasswordField
              required="true"
              label={t("user.field.password")}
              value={inputPassword}
              onInput={(i) => {
                setInputPassword(i.target.value);
                if (i.target.value === "") {
                  setCheckPassword(false);
                } else {
                  setCheckPassword(true);
                }
              }}
              readonly={!allowed}
            />
          )}
        </Can>
        {checkPassword && mobileCheck ? (
          <Can I="update" a="User" field="password" passThrough>
            {(allowed) => (
              <PasswordField
                required="true"
                label={passwordLabel}
                value={inputPasswordCheck}
                onInput={(i) => {
                  setInputPasswordCheck(i.target.value);

                  if (i.target.value === inputPassword) {
                    setPasswordLabel(t("user.field.passwordConfirm"));
                    // send the password to user details!
                    onChange("password", inputPassword);
                  } else {
                    if (i.target.value === "") {
                      i.target.label = t("user.field.passwordConfirm.start");
                    } else {
                      i.target.label = t("user.field.passwordConfirm.noMatch");
                    }
                  }
                  if (i.target.value === user.password) {
                    setPasswordLabel(t("user.field.passwordConfirm"));
                  }
                }}
                readonly={!allowed}
              />
            )}
          </Can>
        ) : null}
        <Can I="update" a="User" field="role" passThrough>
          {(allowed) => (
            <Select
              label={t("user.field.role")}
              placeholder={t("form.selection.choose")}
              value={selectedRole}
              onValueChanged={(i) =>
                onChange("role", resolveRole(i.target.value))
              }
              readonly={!allowed}
            >
              {options.roles
                .filter((role) => hiddenRoles.indexOf(role.id) < 0)
                .map((role) => {
                  return (
                    <Item key={role.id} value={role.id}>
                      {role.name}
                    </Item>
                  );
                })}
            </Select>
          )}
        </Can>
        {checkPassword && !mobileCheck ? (
          <Can I="update" a="User" field="password" passThrough>
            {(allowed) => (
              <PasswordField
                required="true"
                label={passwordLabel}
                value={inputPasswordCheck}
                onInput={(i) => {
                  setInputPasswordCheck(i.target.value);

                  if (i.target.value === inputPassword) {
                    setPasswordLabel(t("user.field.passwordConfirm"));
                    // send the password to user details!
                    onChange("password", inputPassword);
                  } else {
                    if (i.target.value === "") {
                      i.target.label = t("user.field.passwordConfirm.start");
                    } else {
                      i.target.label = t("user.field.passwordConfirm.noMatch");
                    }
                  }
                  if (i.target.value === user.password) {
                    setPasswordLabel(t("user.field.passwordConfirm"));
                  }
                }}
                readonly={!allowed}
              />
            )}
          </Can>
        ) : null}
        <Can I="update" a="User" field="shop_type_access" passThrough>
          {(allowed) => (
            <Select
              label={t("user.field.shoptypeaccess")}
              placeholder={shopTypePlaceholder.join(", ")}
              readonly={!allowed}
            >
              <ListBox
                selectedValues={selectedShopTypeAccess}
                onSelectedValuesChanged={(i) => {
                  // convert indices back to ids
                  const value = [];
                  // eslint-disable-next-line no-restricted-syntax
                  for (const index of i.target.selectedValues) {
                    value.push(options.shopTypes[index]);
                  }

                  // trigger change
                  onChange("shop_type_access", value);
                }}
                multiple
              >
                {options.shopTypes.map((shopType) => {
                  return (
                    <Item key={shopType.id} value={shopType.id}>
                      {shopType.displayName}
                    </Item>
                  );
                })}
              </ListBox>
            </Select>
          )}
        </Can>
        <Can I="update" a="User" field="blocked" passThrough>
          {(allowed) => (
            <vaadin-checkbox-group
              label={t("user.field.blocked")}
              theme="vertical"
            >
              <Checkbox
                theme="readonly"
                checked={!user.blocked}
                onChange={(i) => onChange("blocked", !i.target.checked)}
                disabled={!allowed}
              >
                {t("user.field.accountdisabled")}
              </Checkbox>
            </vaadin-checkbox-group>
          )}
        </Can>
      </FormLayout>
      <vaadin-details className="sis-userform-helpfield">
        <div slot="summary">{t("user.field.userRolesHelp.title")}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: t("user.field.userRolesHelp.content"),
          }}
        ></div>
      </vaadin-details>
    </AbilityContext.Provider>
  );
}

export default withTranslation()(Form);
