import React, { useEffect, useState } from "react";
import DateTime from "luxon/src/datetime.js"; // This needs to go to a own component with the formatdate function

export const formatDate = (date, format) => {
  return DateTime.fromISO(date).toFormat(format || DateTime.DATE_FULL);
};

export const formatTime = (time, format) => {
  return DateTime.fromISO(time).toFormat(format || DateTime.TIME_24_SIMPLE);
};

export const isMobile = () => {
  // first the old userAgent check
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i)
  ) {
    return true;
  }
  // then just check if in portrait mode
  const hide = window.innerHeight > window.innerWidth ? true : false;
  return hide;
};

// This global const is for the unsavedDataWarning
const onUnload = (e) => {
  // the method that will be used for both add and remove event
  e.preventDefault();
  e.returnValue = "";
};
export const unsavedDataWarning = (showWarning) => {
  if (showWarning) {
    window.addEventListener("beforeunload", onUnload);
  } else {
    window.removeEventListener("beforeunload", onUnload);
  }
};
