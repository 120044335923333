import { gql } from "@apollo/client";

export const geocodeQuery = gql`
  query Geocode($address: String) {
    geocode(address: $address) {
      latitude
      longitude
    }
  }
`;

export default geocodeQuery;