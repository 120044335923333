class ConfirmService {
  constructor() {
    this.handlers = [];
    this.lastId = 0;
  }

  /**
   * Register new handler function. Handler will be called whenever new
   * notification is added. Returns unregister function.
   *
   * @param {Function} handler
   */
  registerHandler(handler) {
    this.handlers.push(handler);
    return () => {
      this.handlers = this.handlers.filter((item) => item !== handler);
    };
  }

  show(confirmation) {
    // eslint-disable-next-line no-plusplus
    const data = { id: ++this.lastId, ...confirmation };

    // invoke handlers
    this.handlers.forEach((handler) => handler(data));

    return data.id;
  }
}

export default ConfirmService;
