import { useMutation } from "@apollo/client";
import { get } from "lodash";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useNotificationService } from "../../services/notification";
import FillShopDetails from "./FillShopDetails";
import createQuery, { formatCreateShopQueryData } from "./queries/create-query";
import SelectShopType from "./SelectShopType";
import { VerticalLayout } from "react-vaadin-components";

const shopTemplate = () => ({
  location: {},
  openingHours: {
    weekdays: {},
    exceptions: [],
  },
  services: [],
});

function CreateShop({ t }) {
  const history = useHistory();
  const notifications = useNotificationService();
  const [shop, setShop] = useState(shopTemplate());

  const [executeCreate] = useMutation(createQuery, {
    onCompleted: () => {
      notifications.success({
        content: <p>{t("shop.create.success.msg")}</p>,
      });
    },
    onError: () => {
      notifications.error({
        content: (
          <p>
            <b>{t("common.error.title")}</b>
            <br />
            {t("shop.create.error.msg")}
          </p>
        ),
      });
    },
  });

  const onCreate = async (model) => {
    // format data
    const data = formatCreateShopQueryData(model);
    // exeecute mutation
    const response = await executeCreate({
      variables: { data },
    });
    // resolve id from query result
    const id = get(response, "data.createShop.shop.id");
    if (!parseInt(id, 10)) {
      return false;
    }

    // open the shop
    history.replace(`/shops/${id}`);
    return true;
  };

  const onReset = () => {
    setShop(shopTemplate());
  };

  // show type selection screen first
  if (!shop.type) {
    return (
      <SelectShopType
        onSelect={(type) => setShop({ ...shop, type: type.id })}
      />
    );
  }

  // shop rest of the details after type has been selected
  return <FillShopDetails shop={shop} onBack={onReset} onSubmit={onCreate} />;
}

export default withTranslation()(CreateShop);
