import { gql } from "@apollo/client";

export const deleteShopQuery = gql`
  mutation($id: ID!) {
    deleteShop(input: { where: { id: $id } }) {
      shop {
        id
      }
    }
  }
`;

export default deleteShopQuery;
