import React from "react";
import { withTranslation } from "react-i18next";

import {
  HorizontalLayout,
  FormLayout,
  TextField,
  Button,
} from "react-vaadin-components";

function UserSearchFilter({ filter, onChange, t }) {
  const resetFilters = () => {
    onChange("reset", null);
  };
  return (
    <FormLayout className="sis-container">
      <vaadin-text-field
        label={t("search.input.field")}
        onInput={(i) =>
          onChange("username_contains", !i.target.value ? null : i.target.value)
        }
        value={filter.username_contains}
        clear-button-visible
      />
      <div className="sis-search-reset-container">
        {
          /* if filter has props show reset filters btn */
          filter.hasOwnProperty("username_contains") ? (
            <Button className="sis-search__reset-button" onClick={resetFilters}>
              {t("search.button.reset.filters")}
            </Button>
          ) : null
        }
      </div>
    </FormLayout>
  );
}

export default withTranslation()(UserSearchFilter);
