import { Ability } from "@casl/ability";
import { cloneDeep, isEqual, isArray, isFunction, set } from "lodash";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { VerticalLayout } from "react-vaadin-components";
import * as yup from "yup";
import createYup, { createLocale } from "../../config/createYup";
import { useConfirmService } from "../../services/confirm";
import { useNotificationService } from "../../services/notification";
import Heading from "../Heading";
import ShopForm from "../ShopForm";
import { unsavedDataWarning } from "../../helpers/Functions";

function ShopDetails({
  accessRules,
  actions: actionOverride,
  onDelete,
  onUpdate,
  options,
  shop,
  t,
  title,
}) {
  const ability = new Ability(accessRules);
  const confirm = useConfirmService();
  const notifications = useNotificationService();
  const warning = (p) => {
    unsavedDataWarning(p);
  };

  const [model, setModel] = useState(cloneDeep(shop));
  const [loading, setLoading] = useState();
  const mutated = !isEqual(model, shop);
  const canDelete = ability.can("delete", "Shop");
  const canUpdate = ability.can("update", "Shop");

  const [formValid, setFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  const yup = require("yup");
  yup.setLocale(createLocale(t));

  // validate openingHours
  function validateOpen(label) {
    return yup
      .string(t("yup.validate.time"))
      .label(t(label))
      .matches(/([0-1][0-9]|[2][0-3]):([0-5][0-9])/, t("yup.validate.time"), {
        excludeEmptyString: true,
      })
      .typeError(t("yup.validate.time"));
  }

  const formValidationSchema = yup.object().shape({
    name: yup.string().min(2).required().label(t("common.words.shopName")),
    url: yup.string().url().label(t("common.words.url")),
    email: yup.string().email().nullable().label(t("common.words.email")),
    phone: yup.number().label(t("common.words.phone")),
    password: yup.string().min(4).label(t("common.words.password")),
    location: yup
      .object({
        street: yup.string().min(2).required().label(t("common.words.street")),
        coordinatesLat: yup.number().label(t("common.words.latitude")),
        coordinatesLng: yup.number().label(t("common.words.longitude")),
        postalCode: yup.number().label(t("common.words.postalCode")),
      })
      .required(),
    openingHours: yup.object({
      weekdays: yup.object({
        mondayOpens: validateOpen("yup.validate.mondayOpens"),
        mondayCloses: validateOpen("yup.validate.mondayCloses"),
        tuesdayOpens: validateOpen("yup.validate.tuesdayOpens"),
        tuesdayCloses: validateOpen("yup.validate.tuesdayCloses"),
        wednesdayOpens: validateOpen("yup.validate.wednesdayOpens"),
        wednesdayCloses: validateOpen("yup.validate.wednesdayCloses"),
        thursdayOpens: validateOpen("yup.validate.thursdayOpens"),
        thursdayCloses: validateOpen("yup.validate.thursdayCloses"),
        fridayOpens: validateOpen("yup.validate.fridayOpens"),
        fridayCloses: validateOpen("yup.validate.fridayCloses"),
        saturdayOpens: validateOpen("yup.validate.saturdayOpens"),
        saturdayCloses: validateOpen("yup.validate.saturdayCloses"),
        sundayOpens: validateOpen("yup.validate.sundayOpens"),
        sundayCloses: validateOpen("yup.validate.sundayCloses"),
      }),
      exceptions: yup.array().of(
        yup.object({
          isOpen: yup.boolean(),
          opens: yup
            .string()
            .nullable()
            .when("isOpen", {
              is: true, // alternatively: (val) => val == true
              then: validateOpen("yup.validate.exception.opens").required(),
              otherwise: validateOpen("yup.validate.exception.opens"),
            }),
          closes: yup
            .string()
            .nullable()
            .when("isOpen", {
              is: true, // alternatively: (val) => val == true
              then: validateOpen("yup.validate.exception.closes").required(),
              otherwise: validateOpen("yup.validate.exception.closes"),
            }),
          date: yup
            .date()
            .nullable()
            .when("isOpen", {
              is: true, // alternatively: (val) => val == true
              then: yup
                .date()
                .label(t("yup.validation.exceptions.date"))
                .required(),
              otherwise: yup.date().nullable(),
            }),
        })
      ),
    }),
  });

  const resetModel = () => {
    setModel(cloneDeep(shop));
    warning(false);
  };

  const changeModel = (pathOrList, value) => {
    warning(true);

    // set value(s)
    if (isArray(pathOrList) && isArray(pathOrList[0])) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [p, v] of pathOrList) {
        set(model, p, v);
      }
    } else {
      set(model, pathOrList, value);
    }

    // validate form
    formValidationSchema
      .isValid({
        ...model,
      })
      .then(function (valid) {
        setFormValid(valid);
      });

    // check errors
    formValidationSchema
      .validate({ ...model }, { abortEarly: false })
      .catch(function (err) {
        setFormErrors(err.errors.join(", "));
      });

    // update state
    setModel({ ...model });
  };

  // reset model after prop change
  useEffect(() => {
    resetModel();
  }, [shop]);

  // setup actions
  const actions = {};

  // delete
  if (isFunction(onDelete)) {
    actions.delete = {
      text: t("common.button.delete"),
      disabled: !canDelete || loading,
      loading: loading === "delete",
      theme: "error primary",
      title: canDelete ? null : t("common.permissions.invalid_permissions"),
      callback: () => {
        if (!canDelete || loading) {
          return;
        }

        confirm.show({
          buttonOk: t("common.button.ok"),
          buttonCancel: t("common.button.cancel"),
          onOk: async () => {
            // show loader
            setLoading("delete");

            // trigger callback
            await onDelete();

            // hide loader
            setLoading(null);
          },
          content: <p>{t("shop.delete.confirm.msg")}</p>,
        });
      },
    };
  }

  // reset
  actions.reset = {
    text: t("common.button.reset"),
    disabled: !mutated || loading,
    loading: false,
    theme: "",
    title: "",
    callback: () => {
      if (!mutated || loading) {
        return;
      }

      resetModel();
    },
  };

  // save
  if (isFunction(onUpdate)) {
    actions.save = {
      text: t("common.button.save"),
      disabled: !canUpdate || !mutated || loading,
      loading: loading === "save",
      theme: "success primary",
      title: canUpdate
        ? t("common.button.save")
        : t("common.permissions.invalid_permissions"),
      callback: async () => {
        if (!canUpdate || !mutated || loading) {
          return;
        }
        if (formValid) {
          // show loader
          setLoading("save");

          // trigger callback
          const result = await onUpdate(model);

          // hide loader
          if (!result) {
            setLoading(null);
          }
          // remove save warning
          warning(false);
        } else {
          notifications.error({
            content: (
              <p>
                <b>{t("common.error.title")}</b>
                <br />
                {formErrors}
              </p>
            ),
          });
        }
      },
    };
  }

  // overrides
  if (isFunction(actionOverride)) {
    actionOverride({
      actions,
      ability,
      loading,
      setLoading,
      mutated,
    });
  }

  return (
    <VerticalLayout>
      <Heading
        title={title || shop.name}
        actions={actions}
        options={{ className: "sis-details--heading" }}
        modified={mutated}
      />

      <ShopForm
        shop={model}
        accessRules={accessRules}
        options={options}
        onChange={changeModel}
      />
    </VerticalLayout>
  );
}

export default withTranslation()(ShopDetails);
