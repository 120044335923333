import React, { useEffect, useState } from "react";
import { first, isFunction } from "lodash";
import { useConfirmService } from "./ConfirmContext";
import ConfirmDialog from "../../components/ConfirmDialog";

function ConfirmRenderer() {
  // get service
  const service = useConfirmService();

  // bind notification list to state
  const [confirms, setConfirms] = useState([]);

  useEffect(() => {
    // register handler
    const unregister = service.registerHandler((confirm) => {
      // push to state
      setConfirms(confirms.concat(confirm));
    });

    return () => {
      // unregister handler
      unregister();
    };
  });

  const confirm = first(confirms);

  return (
    <>
      {!confirm ? null : (
        <ConfirmDialog
          key={confirm.id}
          btnOk={confirm.buttonOk || "ok"}
          btnNo={confirm.buttonCancel || "cancel"}
          onOkClicked={() => {
            if (isFunction(confirm.onOk)) confirm.onOk();
            setConfirms(confirms.filter((item) => item !== confirm));
          }}
          onCancelClicked={() => {
            if (isFunction(confirm.onCancel)) confirm.onCancel();
            setConfirms(confirms.filter((item) => item !== confirm));
          }}
        >
          {confirm.content}
        </ConfirmDialog>
      )}
    </>
  );
}

export default ConfirmRenderer;
