import { GoogleApiWrapper, Map, Marker } from "google-maps-react";
import { useQuery } from "@apollo/client";
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";

import Loading from "../../components/Loading";
import { geocodeQuery } from "./queries/geocode-query";

function ShopLocationMap({ google, t, shop, onChange, readOnly }) {
  // resolve current address
  const address = [
    shop.location.street || "",
    shop.location.postalCode || "",
    shop.location.city || "",
    shop.location.country || "",
  ].join(" ");

  const [center, setCenter] = useState(null);
  const [initialAddress, setInitialAddress] = useState(address);
  const [currentAddress, setCurrentAddress] = useState(address);
  const { loading, error, data } = useQuery(geocodeQuery, {
    variables: { address: currentAddress },
  });

  const updateCoordinates = (lat, lng) => {
    onChange([
      ["location.coordinatesLat", lat],
      ["location.coordinatesLng", lng],
    ]);
  };

  useEffect(() => {
    // update address to trigger new query
    setCurrentAddress(address);
  }, [address]);

  useEffect(() => {
    // bail out when loading
    if (loading) {
      return;
    }

    // bail out when error happened
    if (error) {
      return;
    }

    // update coordinates after new ones have been loaded
    const {
      geocode: { latitude, longitude },
    } = data;

    // skip on invalid result
    if (!latitude || !longitude) {
      return;
    }

    // skip if address has not changed, this prevents the initial geocoding
    if (currentAddress === initialAddress) {
      return;
    }

    // clear initial address, this way user can actually enter the same address again
    setInitialAddress(null);

    // update coordinates
    updateCoordinates(latitude, longitude);

    // focus to new location
    setCenter({
      lat: latitude,
      lng: longitude,
    });
  }, [data]);

  return (
    <div className="sis-location__map">
      <Map
        google={google}
        containerStyle={{
          position: "absolute",
          width: "100%",
          height: "100%",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          overflow: "hidden",
        }}
        initialCenter={{
          lat: shop.location.coordinatesLat || 0,
          lng: shop.location.coordinatesLng || 0,
        }}
        center={center}
        zoom={15}
      >
        <Marker
          position={{
            lat: shop.location.coordinatesLat || 0,
            lng: shop.location.coordinatesLng || 0,
          }}
          draggable={!readOnly}
          onDragend={(map, marker, event) =>
            updateCoordinates(event.latLng.lat(), event.latLng.lng())
          }
        />
      </Map>
      {loading && (
        <div className="sis-loading__overlay">
          <Loading />
        </div>
      )}
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAPS_API_KEY,
  // TODO: read this from somewhere
  language: "fi_FI",
})(withTranslation()(ShopLocationMap));
