import { gql } from "@apollo/client";
import shopDetailsFragment from "./shop-details-fragment";

export const fetchShopQuery = gql`
  query Shop($id: ID!) {
    shopAccessRules(id: $id) {
      action
      subject
      fields
      conditions {
        type
      }
      inverted
    }
    shop(id: $id) {
      ...ShopDetails
    }
  }

  ${shopDetailsFragment}
`;

export default fetchShopQuery;
