import { get } from "lodash";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import { withTranslation } from "react-i18next";
import React, { useState } from "react";

import { useNotificationService } from "../../services/notification";
import fetchQuery from "./queries/fetch-query";
import Query from "../../components/Query";
import createQuery, { formatCreateUserQueryData } from "./queries/create-query";
import UserDetails from "../../components/UserDetails";

function CreateUser({ t }) {
  const history = useHistory();
  const notifications = useNotificationService();
  const [user, setUser] = useState({});

  const [executeCreate] = useMutation(createQuery, {
    onCompleted: () => {
      notifications.success({
        content: <p>{t("user.create.success.msg")}</p>,
      });
    },
    onError: () => {
      notifications.error({
        content: (
          <p>
            <b>{t("common.error.title")}</b>
            <br />
            {t("user.create.error.msg")}
          </p>
        ),
      });
    },
  });

  const onCreate = async (model) => {
    // format data
    const data = formatCreateUserQueryData(model);

    // exeecute mutation
    const response = await executeCreate({
      variables: { data },
      refetchQueries: ["Users"],
    });

    // override query result
    const id = get(response, "data.createUser.user.id");
    if (!parseInt(id, 10)) {
      return false;
    }
    if (id === null) {
      return false;
    }

    if (id === undefined) {
      return false;
    }

    // open the user
    history.replace(`/users/${id}`);
    return true;
  };

  return (
    <Query query={fetchQuery}>
      {({ data: { roles, shopTypes, userAccessRules } }) => {
        const options = {
          shopTypes,
          roles,
        };

        return (
          <>
            <UserDetails
              user={user}
              accessRules={userAccessRules}
              options={options}
              onUpdate={onCreate}
            />
          </>
        );
      }}
    </Query>
  );
}

export default withTranslation()(CreateUser);
