class NotificationService {
  constructor() {
    this.handlers = [];
    this.lastId = 0;
  }

  /**
   * Register new handler function. Handler will be called whenever new
   * notification is added. Returns unregister function.
   *
   * @param {Function} handler
   */
  registerHandler(handler) {
    this.handlers.push(handler);
    return () => {
      this.handlers = this.handlers.filter((item) => item !== handler);
    };
  }

  add(notification) {
    // eslint-disable-next-line no-plusplus
    const data = { id: ++this.lastId, ...notification };

    // invoke handlers
    this.handlers.forEach((handler) => handler(data));

    return data.id;
  }

  error(notification) {
    return this.add({ ...notification, type: "error", theme: "error" });
  }

  info(notification) {
    return this.add({ ...notification, type: "info", theme: "primary" });
  }

  notice(notification) {
    return this.add({ ...notification, type: "notice", theme: "notice" });
  }

  success(notification) {
    return this.add({ ...notification, type: "success", theme: "success" });
  }

  warning(notification) {
    return this.add({ ...notification, type: "warning", theme: "error" });
  }
}

export default NotificationService;
