import React, { useState, Children } from "react";

import {
  Dialog,
  VerticalLayout,
  HorizontalLayout,
  Button,
} from "react-vaadin-components";
import Icons from "@vaadin/vaadin-icons";

function ConfirmDialog({
  children,
  btnOk,
  btnNo,
  onOkClicked,
  onCancelClicked,
}) {
  return (
    <div id="dialog-content">
      <Dialog
        id="confirmDialog"
        noCloseOnEsc
        noCloseOnOutsideClick
        opened={true}
      >
        {children}

        <br />
        <HorizontalLayout theme="spacing" style={{ justifyContent: "center" }}>
          <Button
            id="confirmDialog-ok"
            theme="error primary"
            onClick={onOkClicked}
          >
            {btnOk}
          </Button>
          <Button
            id="confirmDialog-cancel"
            theme="contrast primary"
            onClick={onCancelClicked}
          >
            {btnNo}
          </Button>
        </HorizontalLayout>
      </Dialog>
    </div>
  );
}

export default ConfirmDialog;
