import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { gql } from "@apollo/client";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Item, Button, Select } from "react-vaadin-components";
import Icons from "@vaadin/vaadin-icons";

import Query from "../../components/Query";
import ErrorHandler from "../../components/ErrorHandling";
import { useLogout } from "../../config/auth";
import MenuBar from "../../components/MenuBar";
import { isMobile } from "../../helpers/Functions";

function makeMainIcon(img, txt) {
  // @TODO: THIS IS NOT THE WAY TO GO! Fix the styling in theme?!?!
  const item = window.document.createElement("vaadin-context-menu-item");
  const icon = window.document.createElement("iron-icon");
  const text = window.document.createElement("span");
  const iconChevron = window.document.createElement("iron-icon");
  iconChevron.setAttribute("icon", "vaadin:chevron-down-small");
  icon.setAttribute("icon", img);
  item.appendChild(icon);
  if (txt) {
    text.appendChild(window.document.createTextNode(txt));
    text.setAttribute("id", "sis-avatar-menu-text-main");
    item.appendChild(text);
  }
  item.appendChild(iconChevron);
  return item;
}

function makeIcon(img, txt) {
  const item = window.document.createElement("vaadin-context-menu-item");
  const icon = window.document.createElement("iron-icon");
  const text = window.document.createElement("span");
  icon.setAttribute("icon", img);
  icon.setAttribute("class", "sis-avatar-menu-icon");
  item.setAttribute("class", "sis-avatarMenu-name");
  item.appendChild(icon);
  if (txt) {
    text.appendChild(window.document.createTextNode(txt));
    text.setAttribute("class", "sis-avatar-menu-text");
    item.appendChild(text);
  }
  return item;
}

function AvatarMenu({ t }) {
  const logout = useLogout();
  const history = useHistory();
  const mobileCheck = isMobile();

  function GoToUserProfile() {
    history.push("/userprofile");
  }

  const query = gql`
    query Avatar {
      me {
        id
        username
        role {
          id
          name
          description
          type
        }
      }
    }
  `;

  const loader = (
    <Item className="sis-button-loading-avatarMenu">
      <Button>
        <p className="loading-dots"> </p>
      </Button>
    </Item>
  );

  return (
    <Query query={query} loader={loader}>
      {({ data: { me } }) => {
        if (Object.keys(me).length <= 0) {
          return (
            <ErrorHandler
              errorTitle={t("error.title.noUsersFoundInListTitle")}
              error={t("error.message.noUsersFoundInList")}
            />
          );
        }

        const items = [
          {
            component: mobileCheck
              ? makeIcon("vaadin:menu", ``)
              : makeMainIcon("vaadin:user", `${me.username}`),
            children: [
              {
                component: makeIcon("vaadin:user", `${me.username}`),
                callback: () => {
                  GoToUserProfile();
                },
              },
              {
                component: makeIcon("vaadin:exit-o", t("common.auth.logout")),
                callback: () => {
                  logout();
                },
              },
            ],
            // callback: () => console.log("Test1 clicked"),
          },
        ];
        const userIconFound = false; // @TODO: this is still debug
        const userIcon = userIconFound ? (
          `{userIconHERE}`
        ) : (
          <iron-icon class="sis-menu-icon--user" icon="vaadin:user"></iron-icon>
        );

        return (
          <Item className="sis-avatarMenu">
            <MenuBar className="sis-avatarMenu-select" items={items} />
          </Item>
        );
      }}
    </Query>
  );
}

export default withTranslation()(AvatarMenu);
