import React from "react";
import { withTranslation } from "react-i18next";
import { Ability } from "@casl/ability";
import { Checkbox, FormLayout, TextField } from "react-vaadin-components";

import { Can, AbilityContext } from "../ShopCan";

const ShopInfo = function ({ t, shop, onChange, accessRules }) {
  const ability = new Ability(accessRules);

  return (
    <AbilityContext.Provider value={ability}>
      <FormLayout>
        <Can I="update" a="Shop" field="name" passThrough>
          {(allowed) => (
            <TextField
              required="true"
              label={t("shop.field.name")}
              value={shop.name}
              onInput={(i) => onChange("name", i.target.value)}
              readonly={!allowed}
            />
          )}
        </Can>
        <Can I="update" a="Shop" field="url" passThrough>
          {(allowed) => (
            <TextField
              label={t("shop.field.url")}
              value={shop.url}
              onInput={(i) => onChange("url", i.target.value)}
              readonly={!allowed}
            />
          )}
        </Can>
        <Can I="update" a="Shop" field="email" passThrough>
          {(allowed) =>
            allowed ? (
              <vaadin-email-field
                label={t("shop.field.email")}
                name="email"
                value={shop.email}
                error-message={t("shop.field.email.input.error")}
                onInput={(i) => {
                  onChange("email", i.target.value);
                }}
                clear-button-visible
              />
            ) : (
              <vaadin-email-field
                label={t("shop.field.email")}
                value={shop.email}
                readonly
              />
            )
          }
        </Can>
        <Can I="update" a="Shop" field="phone" passThrough>
          {(allowed) => (
            <TextField
              label={t("shop.field.phone")}
              value={shop.phone}
              onInput={(i) => onChange("phone", i.target.value)}
              readonly={!allowed}
            />
          )}
        </Can>
        <Can I="update" a="Shop" field="description" passThrough>
          {(allowed) => (
            <vaadin-text-area
              colspan="2"
              label={t("shop.field.description")}
              value={shop.description}
              onInput={(i) => onChange("description", i.target.value)}
              ref={(e) => {
                if (e) e.readonly = !allowed;
              }}
            />
          )}
        </Can>
        <Can I="update" a="Shop" field="published" passThrough>
          {(allowed) => (
            <vaadin-checkbox-group
              label={t("shop.field.publishing")}
              theme="vertical"
            >
              <Checkbox
                theme="readonly"
                checked={!!shop.published}
                onChange={(i) => onChange("published", !!i.target.checked)}
                disabled={!allowed}
              >
                {t("shop.field.published")}
              </Checkbox>
            </vaadin-checkbox-group>
          )}
        </Can>
      </FormLayout>
    </AbilityContext.Provider>
  );
};
export default withTranslation()(ShopInfo);
