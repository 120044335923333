import _ from "lodash";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

function i18n() {
  this.setup = (resource, options) => {
    const opts = _.defaults(
      {
        lng: "fi",
        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
          escapeValue: false, // react already safes from xss
        },
      },
      options
    );

    i18next
      .use(initReactI18next) // passes i18n down to react-i18next
      .init(
        _.merge(
          {
            resources: resource.get(),
          },
          opts
        )
      );
  };

  this.changeLanguage = (lang) => {
    i18next.changeLanguage(lang);
  };
}

export default new i18n();
