import { isArray } from "lodash";
import { withTranslation } from "react-i18next";
import React, { useState, useRef } from "react";

import {
  FormLayout,
  TextField,
  Select,
  ListBox,
  Item,
  Button,
} from "react-vaadin-components";

function ShopSearchFilter({ filter, onChange, options, t }) {
  const all = {
    id: 0,
    displayName: t("search.selection.all"),
  };

  const resetFilters = () => {
    onChange("reset", null);
  };

  // const inputField = useRef();

  // resolve shop types selected values
  const selectedShopTypes = [];
  const shopTypePlaceholder = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const [index, shopType] of options.shopTypes.entries()) {
    if (
      isArray(filter.type_in) &&
      filter.type_in.find((id) => id === shopType.id)
    ) {
      // add index to selected values
      selectedShopTypes.push(index);

      // add name to placeholder
      shopTypePlaceholder.push(shopType.displayName);
    }
  }

  if (!shopTypePlaceholder.length) {
    shopTypePlaceholder.push(t("form.selection.choose"));
  }

  // resolve service types selected values
  const [serviceSelections, setServiceSelections] = useState([]);
  const [selectServiceValue, setSelectServiceValue] = useState([]);
  const selectedServiceTypes = [];
  const serviceTypePlaceholder = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const [index, serviceType] of options.serviceTypes.entries()) {
    if (
      isArray(filter.services_in) &&
      filter.services_in.find((id) => id === serviceType.id)
    ) {
      // add index to selected values
      selectedServiceTypes.push(index);

      // add name to placeholder
      serviceTypePlaceholder.push(serviceType.displayName);
    }
  }

  if (!serviceTypePlaceholder.length) {
    serviceTypePlaceholder.push(t("form.selection.choose"));
  }

  return (
    <div className="sis-container">
      <FormLayout
        responsiveSteps={[
          { minWidth: 0, columns: 1 },
          { minWidth: "35em", columns: 4 },
        ]}
      >
        <vaadin-text-field
          label={t("search.input.field")}
          onInput={(i) =>
            onChange("name_contains", !i.target.value ? null : i.target.value)
          }
          value={filter.name_contains}
          // ref={inputField}
          colspan="2"
          clear-button-visible
        />

        <Select
          label={t("shop.field.type")}
          placeholder={shopTypePlaceholder}
          // selectedValues={selectedShopTypes}
        >
          <ListBox
            selectedValues={selectedShopTypes}
            onSelectedValuesChanged={(i) => {
              // convert indices back to ids
              const value = [];
              // eslint-disable-next-line no-restricted-syntax
              for (const index of i.target.selectedValues) {
                if (index >= options.shopTypes.length) {
                  // out of bounds -> all
                  onChange("type_in", null);

                  // clear selected values, no idea why this is needed
                  i.target.selectedValues = [];

                  return;
                }

                value.push(options.shopTypes[index].id);
              }

              // trigger change
              onChange("type_in", value.length ? value : null);
            }}
            multiple
          >
            {options.shopTypes.concat([all]).map((shopType) => {
              return (
                <Item key={shopType.id} value={shopType.id}>
                  {shopType.displayName}
                </Item>
              );
            })}
          </ListBox>
        </Select>

        <Select
          label={t("shop.field.service")}
          placeholder={serviceTypePlaceholder.join(", ")}
          selectedValues={selectedServiceTypes}
        >
          <ListBox
            selectedValues={selectedServiceTypes}
            onSelectedValuesChanged={(i) => {
              // convert indices back to ids
              const value = [];
              // eslint-disable-next-line no-restricted-syntax
              for (const index of i.target.selectedValues) {
                if (index >= options.serviceTypes.length) {
                  // out of bounds -> all
                  onChange("services_in", null);

                  // clear selected values, no idea why this is needed
                  i.target.selectedValues = [];

                  return;
                }

                value.push(options.serviceTypes[index].id);
                setServiceSelections(value);
              }

              // trigger change
              onChange("services_in", value.length ? value : null);
            }}
            multiple
          >
            {options.serviceTypes.concat([all]).map((serviceType) => {
              return (
                <Item key={serviceType.id} value={serviceType.id}>
                  {serviceType.displayName}
                </Item>
              );
            })}
          </ListBox>
        </Select>
      </FormLayout>
      <div className="sis-search-reset-container">
        {
          /* if filter has props show reset filters btn */
          filter.hasOwnProperty("type_in") ||
          filter.hasOwnProperty("name_contains") ||
          filter.hasOwnProperty("services_in") ? (
            <Button className="sis-search__reset-button" onClick={resetFilters}>
              {t("search.button.reset.filters")}
            </Button>
          ) : null
        }
      </div>
    </div>
  );
}

export default withTranslation()(ShopSearchFilter);
