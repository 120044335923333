import React, { useRef } from "react";
import { Checkbox, FormLayout, TextField } from "react-vaadin-components";
import { withTranslation } from "react-i18next";
import { cloneDeep } from "lodash";

import ServiceImage from "../Image";
import ServiceOpeningHours from "./openinghours";

function Service({ t, service, onChange, readOnly }) {
  const onChangeRef = useRef();
  const dataRef = useRef();
  dataRef.current = cloneDeep(service);
  onChangeRef.current = onChange;

  return (
    <div className="sis-shop-service">
      <div className="sis-shop-service__info--notInUse">
        <div className="row">
          <div className="column-2">
            <FormLayout responsiveSteps={[{ minWidth: 0, columns: 1 }]}>
              <TextField
                required="true"
                label={t("shop.services.field.type")}
                value={dataRef.current.type.displayName}
                onInput={(i) => {
                  dataRef.current.type = i.target.value;
                  onChangeRef.current(dataRef.current);
                }}
                readonly={readOnly}
              />
              <vaadin-text-area
                label={t("shop.services.field.description")}
                value={dataRef.current.description}
                onInput={(i) => {
                  dataRef.current.description = i.target.value;
                  onChangeRef.current(dataRef.current);
                }}
                ref={(e) => {
                  if (e) e.readonly = !!readOnly;
                }}
              />
              <vaadin-checkbox-group
                label={t("shop.services.field.publishing")}
                theme="vertical"
              >
                <Checkbox
                  theme="readonly"
                  checked={!!dataRef.current.published}
                  onChange={(i) => {
                    dataRef.current.published = i.target.checked;
                    onChangeRef.current(dataRef.current);
                  }}
                  disabled={readOnly}
                >
                  {t("shop.field.published")}
                </Checkbox>
              </vaadin-checkbox-group>
            </FormLayout>
          </div>
          <div className="column-2 additional-div">
            <ServiceImage
              image={dataRef.current.image}
              service={dataRef.current}
              readOnly={readOnly}
              updateImage={(image) => {
                dataRef.current.image = image;
                onChangeRef.current(dataRef.current);
              }}
            />
          </div>
        </div>
      </div>
      <div className="sis-shop-service__opening-hours">
        <h4 className="sis-container--inner__title">
          {t("shop.field.hasOpeningHours")}
        </h4>
        <div className="sis-container--inner">
          <ServiceOpeningHours
            openingHours={dataRef.current.openingHours}
            readOnly={readOnly}
            onChange={(openingHours) => {
              dataRef.current.openingHours = openingHours;
              onChangeRef.current(dataRef.current);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Service);
