import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import { Checkbox } from "react-vaadin-components";
import TimePicker from "../TimePicker";
import { formatTime } from "../../helpers/Functions";

function OpeningHoursWeekday({ day, readOnly, openingHours, onChange, t }) {
  const onChangeRef = useRef();
  const dataRef = useRef();
  const data = cloneDeep(openingHours);
  const getFormattedTimeValue = (v) => {
    return !v ? null : formatTime(v, "HH:mm");
  };
  const getFormattedOutputTimeValue = (v) => {
    return !v ? null : formatTime(v, "HH:mm:ss.SSS");
  };

  dataRef.current = cloneDeep(openingHours);
  onChangeRef.current = onChange;

  return (
    <>
      <label>{t("openinghours.weekday." + day)}</label>
      <TimePicker
        colspan="2"
        label={t("openinghours.weekday.opens")}
        value={getFormattedTimeValue(data.opens)}
        readonly={readOnly}
        onChange={(v) => {
          dataRef.current.opens = getFormattedOutputTimeValue(v);
          onChangeRef.current(dataRef.current);
        }}
      />
      <TimePicker
        colspan="2"
        label={t("openinghours.weekday.closes")}
        value={getFormattedTimeValue(dataRef.current.closes)}
        readonly={readOnly}
        onChange={(v) => {
          dataRef.current.closes = getFormattedOutputTimeValue(v);
          onChangeRef.current(dataRef.current);
        }}
      />
      <Checkbox
        theme="readonly"
        checked={!!dataRef.current.isOpen}
        onChange={(event) => {
          dataRef.current.isOpen = !!event.target.checked;
          onChangeRef.current(dataRef.current);
        }}
        disabled={readOnly}
      >
        {t("openinghours.weekday.is-open")}
      </Checkbox>
    </>
  );
}

export default withTranslation()(OpeningHoursWeekday);
