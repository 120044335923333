import React from "react";
import { getConfirmContext } from "./ConfirmContext";

function ConfirmProvider({ children, service }) {
  // fetch context
  const ConfirmContext = getConfirmContext();

  return (
    <ConfirmContext.Provider value={service}>
      {children}
    </ConfirmContext.Provider>
  );
}

export default ConfirmProvider;
