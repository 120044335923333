import { gql } from "@apollo/client";
import { pick } from "lodash";

export const formatCreateShopQueryData = (source) => {
  // pick generic fields
  const result = pick(source, [
    "type",
    "name",
    "description",
    "image",
    "phone",
    "email",
    "url",
    "location",
    "openingHours",
    "services",
  ]);

  // image
  if ("image" in source) {
    result.image = source.image.id;
  }

  return result;
};

export default gql`
  mutation($data: ShopInput) {
    createShop(input: { data: $data }) {
      shop {
        id
      }
    }
  }
`;
