import React, { useRef, useEffect } from "react";
import DatePickerI18n from "../../lang/datepicker.18n.fi";

function DatePicker({ colspan, label, value, onChange, readonly, required }) {
  const element = useRef();

  useEffect(() => {
    if (!element.current) {
      return;
    }
    // set event listener
    const eventHandler = (event) => {
      onChange(event.target.value);
    };

    element.current.addEventListener("change", eventHandler);
    element.current.readonly = readonly;
    element.current.i18n = DatePickerI18n;

    // eslint-disable-next-line consistent-return
    return () => {
      // clear event handler
      element.current.removeEventListener("change", eventHandler);
    };
  }, [element.current]);

  return (
    <vaadin-date-picker
      colspan={colspan}
      label={label}
      required={required}
      clear-button-visible
      show-week-numbers
      value={value}
      ref={element}
    />
  );
}

export default DatePicker;
/*
<vaadin-date-picker
  label={t("openinghours.exceptions.date")}
  clear-button-visible
  show-week-numbers
  required
  value={dataRef.current.date || null}
  ref={(ref) => {
    if (!ref || ref === dateRef.current) {
      return;
    }

    dateRef.current = ref;
    dateRef.current.readonly = readOnly;

    dateRef.current.i18n = DatePickerI18n;

    dateRef.current.addEventListener("change", function (event) {
      dataRef.current.date = event.target.value;
      onChangeRef.current(dataRef.current);
    });
  }}
/>;
*/
