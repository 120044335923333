import { gql } from "@apollo/client";

export default gql`
  query Users($filter: JSON) {
    users(where: $filter) {
      id
      username
      role {
        name
        type
      }
      shop_type_access {
        displayName
      }
      blocked
      lastLoginAt
    }
    me {
      id
      username
      role {
        id
        name
        description
        type
      }
    }
    userAccessRules {
      action
      subject
      fields
      conditions {
        type
      }
      inverted
    }
  }
`;
