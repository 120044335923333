import { pull } from "lodash";
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Notification } from "react-vaadin-components";
import { useNotificationService } from "./NotificationContext";

function NotificationRenderer({ t }) {
  // get service
  const service = useNotificationService();

  // bind notification list to state
  const [store, setStore] = useState({ notifications: [] });

  useEffect(() => {
    // register handler
    const unregister = service.registerHandler((notification) => {
      // push to state
      store.notifications.push(notification);
      setStore({ ...store });
    });

    return () => {
      // unregister handler
      unregister();
    };
  }, [service]);

  return (
    <>
      {store.notifications.map((notification) => (
        <Notification
          key={notification.id}
          opened
          theme={notification.theme}
          onOpenedChanged={(event) => {
            if (!event.target.opened) {
              pull(store.notifications, notification);
              setStore({ ...store });
            }
          }}
          duration={notification.duration || 4000}
          position={notification.position || "top-end"}
        >
          {notification.content ? (
            notification.content
          ) : (
            <div>{t(notification.message)}</div>
          )}
        </Notification>
      ))}
    </>
  );
}

export default withTranslation()(NotificationRenderer);
