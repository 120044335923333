import { gql } from "@apollo/client";
import userDetailsFragment from "./user-details-fragment";

export default gql`
  query User($id: ID!) {
    user(id: $id) {
      ...UserDetails
    }
    shopTypes {
      id
      displayName
    }
    roles {
      id
      name
      type
    }
    userAccessRules(id: $id) {
      action
      subject
      fields
      conditions {
        type
      }
      inverted
    }
  }

  ${userDetailsFragment}
`;
