import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { gql, useMutation } from "@apollo/client";

import {
  VerticalLayout,
  TextField,
  PasswordField,
  Button,
  Dialog,
} from "react-vaadin-components";

import { useAuthToken } from "../../config/auth";

function Login({ t }) {
  const query = gql`
    mutation Login($identifier: String!, $password: String!) {
      login(input: { identifier: $identifier, password: $password }) {
        jwt
        user {
          id
          username
          email
          confirmed
          blocked
          role {
            id
            name
            description
            type
          }
        }
      }
    }
  `;

  const [authToken, setAuthToken, removeAuthtoken] = useAuthToken();
  const [identifier, setIdentifier] = useState("");
  const [password, setPassword] = useState("");
  const [authenticate, { loading, error }] = useMutation(query, {
    onCompleted: (data) => {
      setAuthToken(data.login.jwt);
    },
    onError: (error) => {
      // on error handler, otherwise apollo throws bad request error
    },
  });

  const login = () => {
    removeAuthtoken();
    authenticate({
      variables: { identifier, password },
    });
  };

  const onKeyPressed = (e) => {
    if (e.key.Enter || e.keyCode === 13) {
      login();
    }
  };

  return (
    <Dialog
      noCloseOnEsc
      noCloseOnOutsideClick
      opened={true}
      modeless={true}
      tabIndex="0"
    >
      <div className="sis-login__heading">
        <h1>{t("login.application.title")}</h1>
        <p>{t("login.application.description")}</p>
      </div>
      <VerticalLayout>
        <h2 className="sis-login__info-heading">{t("login.heading")}</h2>
        {!error ? null : (
          <small className="sis-typography_error-message">
            {t("login.error.msg")}
          </small>
        )}
        <TextField
          disabled={loading}
          className="sis-login__input"
          required
          label={t("login.username")}
          clearButtonVisible
          autofocus={true}
          onkeyup={onKeyPressed}
          onInput={(e) => {
            setIdentifier(e.target.value);
            e.target.focus();
          }}
        />
        <PasswordField
          disabled={loading}
          className="sis-login__input"
          required
          label={t("login.password")}
          clearButtonVisible
          onkeyup={onKeyPressed}
          onInput={(e) => {
            setPassword(e.target.value);
            e.target.focus();
          }}
        />
        <br />
        <Button
          className="sis-login__button"
          theme="primary"
          onkeyup={onKeyPressed}
          onClick={login}
          disabled={!identifier || !password}
        >
          {loading ? t("login.button.loading") : t("login.button.login")}
        </Button>
      </VerticalLayout>
    </Dialog>
  );
}

export default withTranslation()(Login);
