import React from "react";
import Query from "../../components/Query";

import User from "../User";
import fetchQuery from "./queries/fetch-query";

function UserProfile() {
  return (
    <Query query={fetchQuery}>
      {({ data: { me } }) => <User id={me.id} isUserprofile={true} />}
    </Query>
  );
}

export default UserProfile;
