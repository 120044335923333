import { get } from "lodash";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { useMutation } from "@apollo/client";
import { withTranslation } from "react-i18next";
import React, { useState } from "react";

import { useNotificationService } from "../../services/notification";
import deleteQuery from "./queries/delete-query";
import fetchQuery from "./queries/fetch-query";
import Query from "../../components/Query";
import updateQuery, { formatUpdateUserQueryData } from "./queries/update-query";
import UserDetails from "../../components/UserDetails";

function User({ id, t, isUserprofile }) {
  const history = useHistory();
  const notifications = useNotificationService();
  const params = useParams();
  const [sourceOverride, setSourceOverride] = useState();

  if (!id) {
    // eslint-disable-next-line no-param-reassign
    id = params.id;
  }

  const [executeUpdate] = useMutation(updateQuery, {
    onCompleted: () => {
      notifications.success({
        content: <p>{t("user.update.success.msg")}</p>,
      });
    },
    onError: () => {
      notifications.error({
        content: (
          <p>
            <b>{t("common.error.title")}</b>
            <br />
            {t("user.update.error.msg")}
          </p>
        ),
      });
    },
  });

  const [executeDelete] = useMutation(deleteQuery, {
    onCompleted: () => {
      notifications.success({ content: <p>{t("user.delete.success.msg")}</p> });
    },
    onError: () => {
      notifications.error({
        content: (
          <p>
            <b>{t("common.error.title")}</b>
            <br />
            {t("user.delete.error.msg")}
          </p>
        ),
      });
    },
  });

  const onUpdate = async (model) => {
    // format data
    const data = formatUpdateUserQueryData(model);

    // exeecute mutation
    const response = await executeUpdate({
      variables: { data, id },
      refetchQueries: ["Users"],
    });

    // override query result
    // TODO: fix this
    setSourceOverride(get(response, "data.updateUser.user"));
  };

  const onDelete = async () => {
    await executeDelete({
      variables: { id },
      refetchQueries: ["Users"],
    });

    history.replace("/users");
  };

  return (
    <Query query={fetchQuery} variables={{ id }}>
      {({ data: { user, roles, shopTypes, userAccessRules } }) => {
        const options = {
          shopTypes,
          roles,
        };

        return (
          <>
            <UserDetails
              user={sourceOverride || user}
              accessRules={userAccessRules}
              options={options}
              onUpdate={onUpdate}
              onDelete={isUserprofile ? null : onDelete}
            />
          </>
        );
      }}
    </Query>
  );
}

export default withTranslation()(User);
