import { Ability } from "@casl/ability";
import { cloneDeep, isEqual, isFunction, set } from "lodash";
import { VerticalLayout } from "react-vaadin-components";
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import createYup, { createLocale } from "../../config/createYup";

import { useConfirmService } from "../../services/confirm";
import { useNotificationService } from "../../services/notification";
import Form from "./Form";
import Heading from "../Heading";
import { unsavedDataWarning } from "../../helpers/Functions";

function UserDetails({ user, accessRules, options, onUpdate, onDelete, t }) {
  const ability = new Ability(accessRules);
  const confirm = useConfirmService();
  const notifications = useNotificationService();
  const saveWarning = (p) => {
    unsavedDataWarning(p);
  };

  const [model, setModel] = useState(cloneDeep(user));
  const [loading, setLoading] = useState();
  const mutated = !isEqual(model, user);
  const canDelete = ability.can("delete", "User");
  const canUpdate = ability.can("update", "User");

  const [formValid, setFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  const yup = require("yup");
  yup.setLocale(createLocale(t));

  saveWarning(mutated);

  // Check if on create user page or just in update user data page!
  const formValidationSchema =
    window.location.href.indexOf("create") > -1
      ? yup.object().shape({
          email: yup.string().email().required().label(t("common.words.email")),
          password: yup
            .string()
            .required()
            .min(4)
            .label(t("common.words.password")),
        })
      : yup.object().shape({
          email: yup.string().email().required().label(t("common.words.email")),
          password: yup.string().min(4).label(t("common.words.password")),
        });

  const resetModel = () => {
    setModel(cloneDeep(user));
  };

  const changeModel = (path, value) => {
    // set value
    set(model, path, value);

    setFormErrors(null);

    // validate form
    formValidationSchema
      .isValid({
        ...model,
      })
      .then(function (valid) {
        setFormValid(valid);
      });
    // check errors
    formValidationSchema
      .validate({ ...model }, { abortEarly: false })
      .catch(function (err) {
        setFormErrors(err.errors.join(", "));
      });

    // update state
    setModel({ ...model });
  };

  // reset model after prop change
  useEffect(() => {
    resetModel();
  }, [user]);

  const actions = {};

  // delete
  if (isFunction(onDelete)) {
    actions.delete = {
      text: t("common.button.delete"),
      disabled: !canDelete || loading,
      loading: loading === "delete",
      theme: "error primary",
      title: canDelete
        ? t("common.button.delete")
        : t("common.permissions.invalid_permissions"),
      callback: () => {
        if (!canDelete || loading) {
          return;
        }

        confirm.show({
          buttonOk: t("common.button.ok"),
          buttonCancel: t("common.button.cancel"),
          onOk: async () => {
            // show loader
            setLoading("delete");

            // trigger callback
            await onDelete();

            // hide loader
            setLoading(null);
          },
          content: <p>{t("user.delete.confirm.msg")}</p>,
        });
      },
    };
  }

  // reset
  actions.reset = {
    text: t("common.button.reset"),
    disabled: !mutated || loading,
    loading: false,
    theme: "",
    title: t("common.button.reset"),
    callback: () => {
      if (!mutated || loading) {
        return;
      }

      resetModel();
    },
  };

  // save
  if (isFunction(onUpdate)) {
    actions.save = {
      text: t("common.button.save"),
      disabled: !canUpdate || !mutated || loading,
      loading: loading === "save",
      theme: "success primary",
      title: canUpdate
        ? t("common.button.save")
        : t("common.permissions.invalid_permissions"),
      callback: async () => {
        if (!canUpdate || !mutated || loading) {
          return;
        }
        if (formValid) {
          // show loader
          setLoading("save");

          // trigger callback
          await onUpdate(model);

          // hide loader
          setLoading(null);
        } else {
          notifications.error({
            content: (
              <p>
                <b>{t("common.error.title")}</b>
                <br />
                {formErrors}
              </p>
            ),
          });
        }
      },
    };
  }

  return (
    <VerticalLayout>
      <Heading
        title={user.username}
        showBackButton
        backFallback="/users"
        actions={actions}
        options={{ className: "sis-details--heading" }}
        modified={mutated}
        t={t}
      />
      <div className="sis-container">
        <Form
          user={model}
          accessRules={accessRules}
          options={options}
          onChange={changeModel}
        />
      </div>
    </VerticalLayout>
  );
}

export default withTranslation()(UserDetails);
