import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import { cloneDeep } from "lodash";
import { Checkbox, FormLayout } from "react-vaadin-components";

import TimePicker from "../TimePicker";
import DatePicker from "../DatePicker";
import { formatTime } from "../../helpers/Functions";

function OpeningHoursException({ readOnly, exception, onChange, onDelete, t }) {
  const onChangeRef = useRef();
  const onDeleteRef = useRef();
  const dataRef = useRef();

  const getFormattedTimeValue = (v) => {
    return !v ? null : formatTime(v, "HH:mm");
  };
  const getFormattedOutputTimeValue = (v) => {
    return !v ? null : formatTime(v, "HH:mm:ss.SSS");
  };

  onChangeRef.current = onChange;
  onDeleteRef.current = onDelete;
  dataRef.current = cloneDeep(exception);

  return (
    <div className="sis-oh-exceptions__exception">
      <FormLayout
        responsiveSteps={[
          { minWidth: 0, columns: 1 },
          { minWidth: "35em", columns: 4 },
        ]}
      >
        <DatePicker
          label={t("openinghours.exceptions.date")}
          clear-button-visible
          show-week-numbers
          required
          value={dataRef.current.date || null}
          readonly={readOnly}
          onChange={(v) => {
            dataRef.current.date = v;
            onChangeRef.current(dataRef.current);
          }}
        />
        <TimePicker
          label={t("openinghours.weekday.opens")}
          value={getFormattedTimeValue(dataRef.current.opens)}
          readonly={readOnly}
          onChange={(v) => {
            dataRef.current.opens = getFormattedOutputTimeValue(v);
            onChangeRef.current(dataRef.current);
          }}
        />
        <TimePicker
          label={t("openinghours.weekday.closes")}
          value={getFormattedTimeValue(dataRef.current.closes)}
          readonly={readOnly}
          onChange={(v) => {
            dataRef.current.closes = getFormattedOutputTimeValue(v);
            onChangeRef.current(dataRef.current);
          }}
        />

        <Checkbox
          theme="readonly"
          checked={!!dataRef.current.isOpen}
          onChange={(event) => {
            dataRef.current.isOpen = !!event.target.checked;
            onChangeRef.current(dataRef.current);
          }}
          disabled={readOnly}
        >
          {t("openinghours.weekday.is-open")}
        </Checkbox>
      </FormLayout>
      {readOnly ? null : (
        <iron-icon
          class="sis-icon-delete sis-oh-exceptions__exception__delete"
          onClick={() => onDeleteRef.current()}
          icon="vaadin:trash"
        />
      )}
    </div>
  );
}

export default withTranslation()(OpeningHoursException);
