import React from "react";
import { getNotificationContext } from "./NotificationContext";

function NotificationProvider({ children, service }) {
  // fetch context
  const NotificationContext = getNotificationContext();

  return (
    <NotificationContext.Provider value={service}>
      {children}
    </NotificationContext.Provider>
  );
}

export default NotificationProvider;
