import React, { useContext } from "react";

// TODO: do we need to store this somewhere else globally?
let context = null;

export function resetNotificationContext() {
  context = React.createContext();
}

export function getNotificationContext() {
  if (context === null) {
    resetNotificationContext();
  }

  return context;
}

export function useNotificationService() {
  return useContext(getNotificationContext());
}
