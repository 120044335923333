import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Switch,
  Route,
  Redirect,
  Prompt,
  Link,
  NavLink,
} from "react-router-dom";
import { history as memoryHistory } from "../../services/history";
import {
  AppLayout as VappLayout,
  Tabs,
  Tab,
  Button,
  Item,
  HorizontalLayout,
} from "react-vaadin-components";

import { useAuthToken, useLogout } from "../../config/auth";
import Login from "../Login";
import Shop from "../Shop";
import Shops from "../Shops";
import User from "../User";
import Users from "../Users";
import CreateUser from "../CreateUser";
import CreateShop from "../CreateShop";
import UserProfile from "../UserProfile";
import Loading from "../../components/Loading";
import AvatarMenu from "../../components/AvatarMenu";
import logo from "../../assets/images/telia_logo.png";

function Logo() {
  return (
    <img
      className="sis-logo"
      slot="navbar"
      src={logo}
      alt="telia Logo"
      width="135"
      height="76"
      referrerPolicy="no-referrer"
    />
  );
}

function AppLayout() {
  const [authToken] = useAuthToken();
  const logout = useLogout();
  const [loader, setLoader] = useState(false);
  const { t, i18n } = useTranslation();

  function updateLoader(value) {
    setLoader(value);
  }
  useEffect(() => {
    if (loader === true) {
      updateLoader(false); // Hide The Loader
    }
  }, [loader]);

  if (!authToken) {
    return (
      <div className="sis-app">
        <VappLayout>
          <HorizontalLayout
            className="sis-navbar"
            slot="navbar"
            orientation="horizontal"
            theme="minimal"
            style={{ margin: "0 auto", flex: 1 }}
          >
            <div className="block">
              <Logo />
            </div>
          </HorizontalLayout>
          <Login />
        </VappLayout>
      </div>
    );
  }
  return (
    <div className="sis-app">
      <VappLayout>
        <HorizontalLayout
          className="sis-navbar"
          slot="navbar"
          orientation="horizontal"
          theme="minimal"
          style={{ margin: "0 auto", flex: 1 }}
        >
          <div className="block">
            <Logo />
          </div>
          <Tab>
            <NavLink to="/shops" onClick={() => updateLoader(true)}>
              {t("common.nav.shops")}
            </NavLink>
          </Tab>
          <Tab>
            <NavLink to="/users" onClick={() => updateLoader(true)}>
              {t("common.nav.users")}
            </NavLink>
          </Tab>
          <Tab className="sis-avatar-menu">
            <AvatarMenu>
              {/* <Button
              className="btn"
              theme="primary"
              onClick={logout}
            >
              Logout
            </Button> */}
            </AvatarMenu>
          </Tab>
        </HorizontalLayout>
        {!loader ? (
          <div className="sis-app-content">
            <Switch history={memoryHistory}>
              <Redirect from="/login" to="/shops" exact />
              <Redirect from="/" to="/shops" exact />
              <Route path="/shops" component={Shops} exact />
              <Route path="/shops/create" component={CreateShop} exact />
              <Route path="/shops/:id" component={Shop} exact />
              <Route path="/users" component={Users} exact />
              <Route path="/users/create" component={CreateUser} exact />
              <Route path="/users/:id" component={User} exact />
              <Route path="/userprofile" component={UserProfile} exact />
            </Switch>
          </div>
        ) : (
          <Loading></Loading>
        )}
      </VappLayout>
    </div>
  );
}

export default AppLayout;
