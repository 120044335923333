export default {
  fi: {
    translation: {
      "shop.list.title": "Kaupat",
      "shop.create.title": "Uusi kauppa",
      "shop.create.titleWithShopTypeName": "Uusi {{shopType.displayName}}",
      "shop.create.selectShopType": "Valitse kaupan tyyppi",
      "shop.create.success.msg": "Uusi kauppa on luotu",
      "shop.create.error.msg": "Tapahtui virhe kaupan luonnin yhteydessä",
      "shop.field.name": "Kauppa",
      "shop.field.type": "Tyyppi",
      "shop.field.service": "Palvelut",
      "shop.field.location": "Osoite",
      "shop.field.street": "Osoite",
      "shop.field.postalCode": "Postinumero",
      "shop.field.city": "Kaupunki",
      "shop.field.hasImage": "Kuva",
      "shop.field.hasOpeningHours": "Aukioloajat",
      "shop.field.serviceTypes": "Palvelut",
      "shop.field.locality": "Alkuperä",
      "shop.field.url": "Verkkosivu",
      "shop.field.email": "Sähköposti",
      "shop.field.phone": "Puhelinnumero",
      "shop.field.publishing": "Kaupan julkaisu",
      "shop.field.published": "Telia.fi",
      "shops.field.countNoFilter": "Kauppoja: ",
      "shops.field.counts":
        "Hakuehdoilla löytyi: {{current}} / {{total}} kaupasta.",
      "shops.field.counts.typeInUse": "Näytetään {{limit}} ensimmäistä.",
      "shops.field.counts.typeInUse.showRest":
        "Hakuehdoilla löytyi: {{current}} kauppaa.",
      "shops.field.counts.notall":
        "Näytetään {{limit}} ensimmäistä. Voit tarkentaa hakuehtoja tai valita ",
      "shops.field.counts.showAll.warning":
        " (Huom! Kaikkien kauppojen hakeminen kerralla voi kestää jonkin aikaa)",
      "shop.field.description": "Kuvaus",
      "shop.field.email.input.error": "Syötä sähköpostiosoite",
      "shop.dialog.delete.msg":
        "Oletko varma että haluat poistaa tämän kaupan?",
      "shop.update.error.msg": "Kaupan tietojen päivitys epäonnistui",
      "shop.update.success.msg": "Kaupan tiedot on päivitetty",
      "shop.delete.error.msg": "Kaupan poistaminen epäonnistui",
      "shop.delete.success.msg": "Kauppa on poistettu",
      "shop.delete.confirm.msg": "Oletko varma että haluat poistaa kaupan?",
      "shop.refresh.error.msg": "Listan uudelleenhaussa tapahtui virhe!",
      "shop.location.title": "Sijainti",
      "shop.openinghours.title": "Aukioloajat",
      "shop.services.title": "Palvelut",
      "shop.services.add": "Lisää uusi palvelu",
      "shop.services.field.type": "Tyyppi",
      "shop.services.field.description": "Kuvaus",
      "shop.services.field.publishing": "Palveluiden julkaisu",
      "shop.services.no-services-available": "Ei palveluita valittavissa",
      "shop.delete.error.msg": "Kaupan poistaminen epäonnistui",
      "shop.delete.success.msg": "Kauppa on poistettu",

      "user.list.title": "Käyttäjät",
      "user.field.username": "Käyttäjänimi",
      "user.field.email": "Sähköpostiosoite",
      "user.field.password": "Salasana",
      "user.field.passwordConfirm.start": "Syötä salasana uudelleen",
      "user.field.passwordConfirm": "Salasana täsmää!",
      "user.field.passwordConfirm.noMatch":
        "Salasanat eivät täsmää! Tarkista salasana",
      "user.field.email.input.error": "Syötä oikea sähköpostiosoite",
      "user.field.role": "Käyttäjärooli",
      "user.field.shoptypeaccess": "Kauppojen hallinta",
      "user.field.blocked": "Käyttäjän aktiivisuus",
      "user.field.accountdisabled": "Tili aktivoitu",
      "user.field.lastlogin": "Viimeksi kirjautunut",
      "user.field.editheader": "",
      "user.field.userRolesHelp.title": "Käyttäjärooliopastus",
      "user.field.userRolesHelp.content":
        '<table style="width:100%"> <tr> <th style="text-align: left">Rooli</th> <th>Kaupat</th> <th>Käyttäjät</th> <th>Feedit</th> </tr> <tr> <td>Administrator</td> <td style="text-align: center;"><iron-icon style="width: 15px; height: 15px;" icon="vaadin:pencil"/></td> <td style="text-align: center;"><iron-icon style="width: 15px; height: 15px;" icon="vaadin:pencil"/></td> <td style="text-align: center;"></td> </tr> <tr> <td>Content Manager</td> <td style="text-align: center;"><iron-icon style="width: 15px; height: 15px;" icon="vaadin:pencil"/></td> <td style="text-align: center;"><iron-icon style="width: 15px; height: 15px;" icon="vaadin:eye"/></td> <td style="text-align: center;"></td> </tr> <tr> <td>API consumer</td> <td style="text-align: center;"></td> <td style="text-align: center;"></td> <td style="text-align: center;"><iron-icon style="width: 15px; height: 15px;" icon="vaadin:eye"/></td> </tr> </table>',
      "user.update.error.msg": "Käyttäjän tietojen päivitys epäonnistui",
      "user.update.success.msg": "Käyttäjän tiedot on päivitetty",
      "user.create.success.msg": "Uusi käyttäjä on luotu",
      "user.create.error.msg": "Tapahtui virhe käyttäjän luonnin yhteydessä",
      "user.delete.error.msg": "Käyttäjän poistaminen epäonnistui",
      "user.delete.success.msg": "Käyttäjä on poistettu",
      "user.delete.confirm.msg": "Oletko varma että haluat poistaa käyttäjän?",

      "user.userprofile.title": "Omat Käyttäjätiedot",

      "search.title": "Haku",
      "search.input.field": "Hakuehdot",
      "search.selection.all": "Kaikki",
      "search.selection.multipleChoices": "Useita valintoja...",
      "search.button.reset.filters": "Tyhjennä hakuehdot",

      "form.selection.choose": "Valitse",

      "common.nav.shops": "Kaupat",
      "common.nav.users": "Käyttäjät",
      "common.button.ok": "Kyllä",
      "common.button.cancel": "Peruuta",
      "common.button.search": "Etsi",
      "common.button.save": "Tallenna",
      "common.button.back": "Takaisin",
      "common.button.reset": "Palauta",
      "common.button.delete": "Poista",
      "common.button.create": "Uusi",
      "common.button.next": "Seuraava",
      "common.text.showAll": "Näytä kaikki",
      "common.text.showRest": "Näytä loput",
      "common.info.username": "Käyttäjänimi",
      "common.info.role": "Käyttäjärooli",
      "common.auth.logout": "Kirjaudu ulos",

      "common.permissions.invalid_permissions": "Käyttöoikeudet eivät riitä",
      "common.error.title": "Virhe!",
      "common.confirm.save.warning":
        "Haluatko varmasti poistua? Menetät tallentamattomat muutokset.",

      "common.image.no-image": "Ei kuvaa",

      "location.street.label": "Katuosoite",
      "location.city.label": "Kaupunki",
      "location.postalCode.label": "Postinumero",
      "location.country.label": "Maa",
      "location.coordinatesLat.label": "Lat",
      "location.coordinatesLng.label": "Lng",
      "location.detailedAddress.label": "Tarkka osoite",
      "location.directions.label": "Saapumisohjeet",
      "location.map.no-map": "Karttaa ei saatavilla",

      "login.application.title": "Kartta API",
      "login.application.description": "Karttapalvelun hallintajärjestelmä",
      "login.heading": "Kirjaudu sisään",
      "login.username": "Käyttäjätunnus",
      "login.password": "Salasana",
      "login.button.login": "Kirjaudu",
      "login.button.loading": "Ladataan...",
      "login.error.msg": "Kirjautuminen ei onnistunut",

      "error.title.queryError": "Virhe haussa",
      "error.title.noShopsFoundInListTitle": "Etsintä ei tuottanut tulosta!",
      "error.message.noShopsFoundInList":
        "Pahoittelut, hakuehdoilla ei löytynyt kauppoja!",
      "error.title.noUsersFoundInListTitle": "Etsintä ei tuottanut tulosta!",
      "error.message.noUsersFoundInList":
        "Pahoittelut, hakuehdoilla ei löytynyt käyttäjiä!",
      "error.title.invalidAuthToken": "Kirjautuminen vaaditaan",
      "error.message.shopNotFound": "Kauppaa ei löydy",
      "error.message.no.permission":
        "Sinulla ei ole riittäviä oikeuksia nähdä kyseistä sisältöä!",
      "error.message.networkError": "Verkkovirhe, tarkista internet-yhteytesi!",
      "error.message.general": "Tapahtui virhe!",
      "error.invalid.datetime": "Ei koskaan",

      "openinghours.weekday.monday": "Maanantai",
      "openinghours.weekday.tuesday": "Tiistai",
      "openinghours.weekday.wednesday": "Keskiviikko",
      "openinghours.weekday.thursday": "Torstai",
      "openinghours.weekday.friday": "Perjantai",
      "openinghours.weekday.saturday": "Lauantai",
      "openinghours.weekday.sunday": "Sunnuntai",
      "openinghours.weekday.opens": "Aukeaa",
      "openinghours.weekday.closes": "Sulkeutuu",
      "openinghours.weekday.is-open": "On auki",
      "openinghours.exceptions.title": "Poikkeukset",
      "openinghours.exceptions.date": "Päivämäärä",
      "openinghours.exceptions.add": "Lisää uusi poikkeus",
      "openinghours.exceptions.no-exceptions": "Ei poikkeusaukioloaikoja",

      "shop.publish.isPublished": "Kauppa on julkaistu Telia.fi:hin",
      "shop.publish.isNotPublished": "Kauppa ei ole julkaistu Telia.fi:hin",
      "shop.publish.isCannotPublished":
        "Kauppaa ei voi julkaista Telia.fi:hin!",
      "shop.publish.nameMissing": "Kaupan nimi puuttuu!",
      "shop.publish.coordinatesMissing": "Koordinaatit (lat/lng) puuttuu!",

      "yup.validate.invalid": "${path} ei kelpaa",
      "yup.validate.required": "${path} on pakollinen",
      "yup.validate.string.length":
        "${path} täytyy olla tasan ${length} kirjainta pitkä",
      "yup.validate.string.min":
        "${path} täytyy olla vähintään ${min} kirjainta pitkä.",
      "yup.validate.email": "Sähköpostiosoite on väärin",
      "yup.validate.url": "${path} täytyy olla oikea www-osoite",
      "yup.validate.number.min":
        "${path} täytyy olla vähemmän kuin ${min} numeroa",
      "yup.validate.number.max":
        "${path} ei saa olla pidempi kuin ${max} numeroa",
      "yup.validate.mustbea": " täytyy olla ",
      "yup.validate.time": "${label} pitää olla muodossa esim. 10:00",
      "yup.validate.mondayOpens": "Maanantain aukenemisaika",
      "yup.validate.mondayCloses": "Maanantain sulkemisaika",
      "yup.validate.tuesdayOpens": "Tiistain aukenemisaika",
      "yup.validate.tuesdayCloses": "Tiistain sulkemisaika",
      "yup.validate.wednesdayOpens": "Keskiviikon aukenemisaika",
      "yup.validate.wednesdayCloses": "Keskiviikon sulkemisaika",
      "yup.validate.thursdayOpens": "Torstain aukenemisaika",
      "yup.validate.thursdayCloses": "Torstain sulkemisaika",
      "yup.validate.fridayOpens": "Perjantain aukenemisaika",
      "yup.validate.fridayCloses": "Perjantain sulkemisaika",
      "yup.validate.saturdayOpens": "Lauantain aukenemisaika",
      "yup.validate.saturdayCloses": "Lauantain sulkemisaika",
      "yup.validate.sundayOpens": "Sunnuntain aukenemisaika",
      "yup.validate.sundayCloses": "Sunnuntain sulkemisaika",
      "yup.validate.exception.opens": "Poikkeusaukiolon aukenemisaika",
      "yup.validate.exception.closes": "Poikkeusaukiolon sulkemisaika",
      "yup.validation.exceptions.date": "Poikkeusaukiolon päivämäärä",

      "common.words.shopName": "Kaupan nimi (kauppa)",
      "common.words.password": "Salasana",
      "common.words.email": "Sähköpostiosoite",
      "common.words.blockedAccount": "Tili aktivoitu kenttä",
      "common.words.url": "Verkkosivuosoite",
      "common.words.street": "Katuosoite",
      "common.words.latitude": "Leveysaste (Lat)",
      "common.words.longitude": "Pituusaste (Lng)",
      "common.words.number": "numero",
      "common.words.string": "tekstimuodossa",
      "common.words.boolean": "looginen (on/off) -muodossa",
      "common.words.array": "listamuodossa",
      "common.words.object": "listamuodossa",
      "common.words.postalCode": "Postinumero",
      "common.words.phone": "Puhelinnumero",
      "common.words.published": "Julkaistu",
    },
  },
  // as an example
  en: {
    translation: {
      "shop.field.name": "Shop",
      "shop.field.type": "Type",
      "shop.field.location": "Address",
      "shop.field.hasImage": "Has Image",
      "shop.field.hasOpeningHours": "Has Opening Hours",
      "shop.field.serviceTypes": "Services",
      "shop.field.locality": "Source",
      "shop.dialog.delete.msg": "Are you sure you want to delete this shop?",
      "common.button.ok": "Yes",
      "common.button.cancel": "Cancel",
    },
  },
};
