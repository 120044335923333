import React from "react";
import { withTranslation } from "react-i18next";
import { Ability } from "@casl/ability";
import { FormLayout } from "react-vaadin-components";

const ShopLocation = function ({ t, shop, onChange, accessRules }) {
  const ability = new Ability(accessRules);
  const allowed = ability.can("update", "Shop", "location");

  return (
    <FormLayout
      responsiveSteps={[
        { minWidth: 0, columns: 1 },
        { minWidth: "20em", columns: 4 },
        { minWidth: "30em", columns: 10 },
      ]}
    >
      <vaadin-text-field
        colspan="6"
        required="true"
        label={t("location.street.label")}
        value={shop.location.street}
        onInput={(i) => onChange("location.street", i.target.value)}
        ref={(e) => {
          if (e) e.readonly = !allowed;
        }}
      />

      <vaadin-text-field
        colspan="4"
        label={t("location.city.label")}
        value={shop.location.city}
        onInput={(i) => onChange("location.city", i.target.value)}
        ref={(e) => {
          if (e) e.readonly = !allowed;
        }}
      />

      <vaadin-text-field
        colspan="2"
        label={t("location.postalCode.label")}
        value={shop.location.postalCode}
        onInput={(i) => onChange("location.postalCode", i.target.value)}
        ref={(e) => {
          if (e) e.readonly = !allowed;
        }}
      />

      <vaadin-text-field
        colspan="4"
        label={t("location.country.label")}
        value={shop.location.country}
        onInput={(i) => onChange("location.country", i.target.value)}
        ref={(e) => {
          if (e) e.readonly = !allowed;
        }}
      />
      <vaadin-text-field
        colspan="2"
        label={t("location.coordinatesLat.label")}
        value={shop.location.coordinatesLat}
        onInput={(i) => onChange("location.coordinatesLat", i.target.value)}
        ref={(e) => {
          if (e) e.readonly = !allowed;
        }}
      />
      <vaadin-text-field
        colspan="2"
        label={t("location.coordinatesLng.label")}
        value={shop.location.coordinatesLng}
        onInput={(i) => onChange("location.coordinatesLng", i.target.value)}
        ref={(e) => {
          if (e) e.readonly = !allowed;
        }}
      />
      <vaadin-text-field
        colspan="10"
        label={t("location.detailedAddress.label")}
        value={shop.location.detailedAddress}
        onInput={(i) => onChange("location.detailedAddress", i.target.value)}
        ref={(e) => {
          if (e) e.readonly = !allowed;
        }}
      />
      <vaadin-text-area
        colspan="10"
        label={t("location.directions.label")}
        value={shop.location.directions}
        onInput={(i) => onChange("location.directions", i.target.value)}
        ref={(e) => {
          if (e) e.readonly = !allowed;
        }}
      />
    </FormLayout>
  );
};
export default withTranslation()(ShopLocation);
