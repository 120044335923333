import DateTime from "luxon/src/datetime"; //

export default {
  week: "viikko",
  calendar: "kalenteri",
  clear: "tyhjennä",
  today: "tänään",
  cancel: "peruuta",
  firstDayOfWeek: 1,
  monthNames: "tammikuu_helmikuu_maaliskuu_huhtikuu_toukokuu_kesäkuu_heinäkuu_elokuu_syyskuu_lokakuu_marraskuu_joulukuu".split(
    "_"
  ),
  weekdays: "sunnuntai_maanantai_tiistai_keskiviikko_torstai_perjantai_lauantai".split(
    "_"
  ),
  weekdaysShort: "su_ma_ti_ke_to_pe_la".split("_"),
  formatDate: (dateObject) => {
    const date = DateTime.fromObject(dateObject);
    return date.setLocale("fi").toFormat("d.M.yyyy");
  },
  formatTitle: (monthName, fullYear) => {
    return [monthName, fullYear].join(" ");
  },
  parseDate: (dateString) => {
    const date = DateTime.fromFormat(dateString, "d.M.yyyy");
    return {
      day: date.day,
      month: date.month,
      year: date.year,
    };
  },
};
