import React from "react";
import { withTranslation } from "react-i18next";
import { VerticalLayout } from "react-vaadin-components";
import Query from "../../components/Query";
import ShopDetails from "../../components/ShopDetails";
import fetchQuery from "./queries/fetch-query";

function FillShopDetails({ t, shop, onBack, onSubmit }) {
  const actionsOverride = ({ actions, loading }) => {
    // delete normal reset
    delete actions.reset;

    // back
    actions.back = {
      text: t("common.button.back"),
      disabled: loading,
      loading: loading === "back",
      theme: "",
      title: "",
      callback: () => {
        if (loading) {
          return;
        }

        onBack();
      },
    };

    // reorder
    const { save } = actions;
    delete actions.save;
    actions.save = save;
  };

  return (
    <Query query={fetchQuery} variables={{ shopType: shop.type }}>
      {({ data: { shopTypes, shopAccessRules } }) => {
        const shopType = shopTypes.find((type) => type.id === shop.type);
        return (
          <VerticalLayout>
            <ShopDetails
              title={t("shop.create.titleWithShopTypeName", { shopType })}
              shop={shop}
              accessRules={shopAccessRules}
              actions={actionsOverride}
              onUpdate={onSubmit}
            />
          </VerticalLayout>
        );
      }}
    </Query>
  );
}

export default withTranslation()(FillShopDetails);
