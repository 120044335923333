import React from "react";
import { useHistory } from "react-router-dom";
import { Button, HorizontalLayout } from "react-vaadin-components";
import { isMobile, unsavedDataWarning } from "../../helpers/Functions";

function Heading({
  title,
  actions = {},
  options = {},
  modified,
  t,
  showBackButton,
  backFallback,
}) {
  const history = useHistory();
  const mobileCheck = isMobile();
  const saveWarning = (p) => {
    unsavedDataWarning(p);
  };

  saveWarning(false); // is false at first...

  function goToPreviousPage() {
    if (modified) {
      if (!window.confirm(t("common.confirm.save.warning"))) {
        return;
      }
    }

    // handle entrypoint differently, i.e. got to fallback address
    if (history.isCurrentlyAtEntrypoint()) {
      if (backFallback) {
        history.push(backFallback);
        return;
      }

      throw new Error("nowhere to go back to!");
    }

    // go back normally
    history.go(-1);
  }

  // init back button
  const backButton = showBackButton ? (
    <Button className="sis-button--back" onClick={() => goToPreviousPage()}>
      <iron-icon
        id="icon-back"
        className="sis-icon-back"
        icon="vaadin:arrow-left"
      />
    </Button>
  ) : null;

  return (
    <>
      {mobileCheck ? (
        <div id="mobile-header">
          <h3>
            {backButton}
            {"   "}
            {title}
          </h3>
          <div />
        </div>
      ) : null}
      <HorizontalLayout className={options.className}>
        {mobileCheck ? null : (
          <div>
            <h3>
              {backButton}
              {"   "}
              {title}
            </h3>
            <div />
          </div>
        )}

        <HorizontalLayout theme="spacing">
          {Object.entries(actions).map(([key, action]) => (
            <Button
              key={`sis-action-${key}`}
              theme={action.theme}
              disabled={action.disabled}
              onClick={action.callback}
              title={action.title}
            >
              {action.loading ? (
                <iron-icon class="sis-icon--spinning" icon="vaadin:spinner" />
              ) : (
                action.text
              )}
            </Button>
          ))}
        </HorizontalLayout>
      </HorizontalLayout>
    </>
  );
}

export default Heading;
