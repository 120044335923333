import { useQuery } from "@apollo/client";
import React from "react";

import ErrorHandler from "../ErrorHandling";
import Loading from "../Loading";

const Query = ({
  children,
  query,
  variables,
  pollInterval,
  loader,
  fetchPolicy,
}) => {
  const { data, loading, error } = useQuery(query, {
    variables,
    pollInterval,
    fetchPolicy,
  });

  if (loading) {
    return loader || <Loading />;
  }

  if (error) {
    return <ErrorHandler errorTitle="VIRHE:" error={JSON.stringify(error)} />;
  }

  return children({ data });
};

export default Query;
