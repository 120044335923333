import React, { useCallback, useState, useEffect } from "react";
import { cloneDeep, debounce, get, set, isArray, isEqual } from "lodash";
import { withTranslation } from "react-i18next";
import { Ability } from "@casl/ability";
import * as yup from "yup";

import Heading from "../../components/Heading";
import ShopForm from "../../components/ShopForm";

import createYup, { createLocale } from "../../config/createYup";
import { useConfirmService } from "../../services/confirm";
import { useNotificationService } from "../../services/notification";
import { unsavedDataWarning } from "../../helpers/Functions";

const ShopContainer = function ({
  t,
  shop,
  shopAccessRules,
  onUpdate,
  onDelete,
  error,
}) {
  const ability = new Ability(shopAccessRules);
  const confirm = useConfirmService();
  const notifications = useNotificationService();
  const saveWarning = (p) => {
    unsavedDataWarning(p);
  };

  // Copy of the origina values
  const [mutatableShop, mutateShop] = useState(cloneDeep(shop));
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [formValid, setFormValid] = useState(false);
  const [formErrors, setFormErrors] = useState(null);

  const yup = require("yup");
  yup.setLocale(createLocale(t));

  // validate openingHours
  function validateOpen(label) {
    return yup
      .string(t("yup.validate.time"))
      .nullable()
      .label(t(label))
      .matches(/([0-1][0-9]|[2][0-3]):([0-5][0-9])/, t("yup.validate.time"), {
        excludeEmptyString: true,
      })
      .typeError(t("yup.validate.time"));
  }

  const formValidationSchema = yup.object().shape({
    name: yup.string().min(2).required().label(t("common.words.shopName")),
    url: yup.string().url().nullable().label(t("common.words.url")),
    email: yup.string().email().nullable().label(t("common.words.email")),
    phone: yup.number().nullable().label(t("common.words.phone")),
    password: yup.string().min(4).nullable().label(t("common.words.password")),
    location: yup.object({
      street: yup.string().min(2).required().label(t("common.words.street")),
      coordinatesLat: yup.number().label(t("common.words.latitude")),
      coordinatesLng: yup.number().label(t("common.words.longitude")),
      postalCode: yup.number().nullable().label(t("common.words.postalCode")),
    }),
    openingHours: yup
      .object({
        weekdays: yup.object({
          mondayOpens: validateOpen("yup.validate.mondayOpens"),
          mondayCloses: validateOpen("yup.validate.mondayCloses"),
          tuesdayOpens: validateOpen("yup.validate.tuesdayOpens"),
          tuesdayCloses: validateOpen("yup.validate.tuesdayCloses"),
          wednesdayOpens: validateOpen("yup.validate.wednesdayOpens"),
          wednesdayCloses: validateOpen("yup.validate.wednesdayCloses"),
          thursdayOpens: validateOpen("yup.validate.thursdayOpens"),
          thursdayCloses: validateOpen("yup.validate.thursdayCloses"),
          fridayOpens: validateOpen("yup.validate.fridayOpens"),
          fridayCloses: validateOpen("yup.validate.fridayCloses"),
          saturdayOpens: validateOpen("yup.validate.saturdayOpens"),
          saturdayCloses: validateOpen("yup.validate.saturdayCloses"),
          sundayOpens: validateOpen("yup.validate.sundayOpens"),
          sundayCloses: validateOpen("yup.validate.sundayCloses"),
        }),
        exceptions: yup.array().of(
          yup.object({
            isOpen: yup.boolean(),
            opens: yup
              .string()
              .nullable()
              .when("isOpen", {
                is: true, // alternatively: (val) => val == true
                then: validateOpen("yup.validate.exception.opens").required(),
                otherwise: validateOpen("yup.validate.exception.opens"),
              }),
            closes: yup
              .string()
              .nullable()
              .when("isOpen", {
                is: true, // alternatively: (val) => val == true
                then: validateOpen("yup.validate.exception.closes").required(),
                otherwise: validateOpen("yup.validate.exception.closes"),
              }),
            date: yup
              .date()
              .nullable()
              .when("isOpen", {
                is: true, // alternatively: (val) => val == true
                then: yup
                  .date()
                  .label(t("yup.validation.exceptions.date"))
                  .required(),
                otherwise: yup.date().nullable(),
              }),
          })
        ),
      })
      .nullable(),
  });

  const updateValidationStatus = useCallback(
    debounce((shop) => {
      // validate form
      formValidationSchema.isValid(shop).then(setFormValid);

      // check errors
      formValidationSchema
        .validate(shop, { abortEarly: false })
        .catch((err) => {
          setFormErrors(err.errors.join(", "));
        });
    }, 250),
    [shop]
  );

  const onShopDelete = () => {
    confirm.show({
      buttonOk: t("common.button.ok"),
      buttonCancel: t("common.button.cancel"),
      onOk: () => {
        setDeleteLoading(true);
        onDelete();
      },
      content: <p>{t("shop.delete.confirm.msg")}</p>,
    });
  };

  const onReset = () => {
    setUpdateLoading(false);
    mutateShop(cloneDeep(shop));
  };

  const onSave = () => {
    if (formValid) {
      setUpdateLoading(true);
      onUpdate(mutatableShop);
    } else {
      notifications.error({
        content: (
          <p>
            <b>{t("common.error.title")}</b>
            <br />
            {formErrors}
          </p>
        ),
      });
    }
  };

  useEffect(() => {
    onReset();
  }, [shop]);

  // handle error that happened during queries
  useEffect(() => {
    if (error) {
      setUpdateLoading(false);
      setDeleteLoading(false);
    }
  }, [error]);

  const mutated = !isEqual(mutatableShop, shop);
  const canDelete = ability.can("delete", "Shop");
  const canUpdate = ability.can("update", "Shop");

  saveWarning(mutated);

  const actions = {
    delete: {
      text: t("common.button.delete"),
      disabled: !canDelete || deleteLoading || updateLoading,
      loading: deleteLoading,
      theme: "error primary",
      title: canDelete ? null : t("common.permissions.invalid_permissions"),
      callback: () => canDelete && onShopDelete(),
    },
    reset: {
      text: t("common.button.reset"),
      disabled: !mutated || deleteLoading || updateLoading,
      loading: false,
      theme: "",
      title: "",
      callback: () => onReset(),
    },
    save: {
      text: t("common.button.save"),
      disabled: !canUpdate || !mutated || deleteLoading || updateLoading,
      loading: updateLoading,
      theme: "success primary",
      title: canUpdate ? null : t("common.permissions.invalid_permissions"),
      callback: () => canUpdate && onSave(),
    },
  };

  const shopOnChange = (pathOrList, value) => {
    const newShop = cloneDeep(mutatableShop);

    const prepareValue = (p, v) => {
      // make sure not to mutate orignal null values to empty strings
      const originalValue = get(mutatableShop, p);
      if (originalValue === null && !v && v !== 0) {
        return null;
      }

      return v;
    };

    if (isArray(pathOrList) && isArray(pathOrList[0])) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [p, v] of pathOrList) {
        set(newShop, p, prepareValue(p, v));
      }
    } else {
      set(newShop, pathOrList, prepareValue(pathOrList, value));
    }

    mutateShop(newShop);

    updateValidationStatus({ ...newShop });
  };

  const isLoading = deleteLoading || updateLoading;

  return (
    <>
      <Heading
        title={mutatableShop.name}
        showBackButton
        backFallback="/shops"
        actions={actions}
        options={{ className: "sis-details--heading" }}
        modified={mutated}
        t={t}
      />

      <ShopForm
        loading={isLoading}
        shop={mutatableShop}
        accessRules={shopAccessRules}
        onChange={shopOnChange}
      />
    </>
  );
};

export default withTranslation()(ShopContainer);
