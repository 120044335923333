import { gql } from "@apollo/client";
import { pick } from "lodash";

export const formatCreateUserQueryData = (source) => {
  // pick generic fields
  const result = pick(source, ["email", "password", "blocked"]);

  // force email as username
  result.username = result.email;

  // start as confirmed
  result.confirmed = true;

  // role
  if ("role" in source) {
    result.role = source.role.id;
  }

  // shop type access
  if ("shop_type_access" in source) {
    result.shop_type_access = source.shop_type_access.map(
      (shopType) => shopType.id
    );
  }

  return result;
};

export default gql`
  mutation($data: UserInput) {
    createUser(input: { data: $data }) {
      user {
        id
        username
        email
        blocked
        shop_type_access {
          id
          name
          displayName
        }
        role {
          id
          name
        }
      }
    }
  }
`;
