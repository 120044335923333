import { gql } from "@apollo/client";

export default gql`
  query CreateUser {
    shopTypes {
      id
      displayName
    }
    roles {
      id
      name
      type
    }
    userAccessRules {
      action
      subject
      fields
      conditions {
        type
      }
      inverted
    }
  }
`;
