import React from "react";
import { Button, Item, Grid, GridColumn } from "react-vaadin-components";
import Icons from "@vaadin/vaadin-icons";
import { withTranslation } from "react-i18next";

function ErrorHandler({ errorTitle, error, t }) {
  errorTitle = errorTitle ? errorTitle : "error.defaultTitle";
  error = error ? error : t("error.message.general");
  if (process.env.REACT_APP_ERROR_LOGGING) {
    console.log("Error: ", error);
  }
  return (
    <Item className="sis-message--error">
      <iron-icon
        className="sis-message--error--icon"
        icon="vaadin:exclamation-circle-o"
      ></iron-icon>
      <strong className="sis-message--error--title"> {errorTitle} </strong>
      <div className="sis-message--error--message">
        {error.includes('message":"Forbidden')
          ? t("error.message.no.permission")
          : error.includes('message":"shop not found')
          ? t("error.message.shopNotFound")
          : error.includes("networkError")
          ? t("error.message.networkError")
          : error}
      </div>
    </Item>
  );
}

export default withTranslation()(ErrorHandler);
