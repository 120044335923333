import { isFunction } from "lodash";
import React, { useRef } from "react";
import "@vaadin/vaadin-upload";
import { useAuthToken } from "../../config/auth";

function Upload({ image, onChange, className }) {
  const element = useRef();
  const [authToken] = useAuthToken();

  return (
    <vaadin-upload
      class={className}
      max-files="1"
      accept="image/png,image/jpeg"
      ref={(ref) => {
        // skip unchanged
        if (ref === element.current) {
          return;
        }

        if (!ref) {
          return;
        }

        // store
        element.current = ref;

        // init files
        ref.files = image
          ? [{ name: image.name, progress: 100, complete: true }]
          : [];

        // setup handlers
        ref.addEventListener("files-changed", (event) => {
          const {
            detail: { path },
          } = event;

          // detect removals
          if (path === "files.length" && !event.target.files.length) {
            if (isFunction(onChange)) {
              onChange(null);
            }
          }
        });

        ref.addEventListener("upload-before", (event) => {
          const {
            detail: { file },
          } = event;

          // set backend url
          file.uploadTarget = `${process.env.REACT_APP_BACKEND_URL}/upload`;

          // set parameter name
          file.formDataName = "files";
        });

        ref.addEventListener("upload-request", (event) => {
          // authorization header
          event.detail.xhr.setRequestHeader(
            "Authorization",
            `Bearer ${authToken}`
          );
        });

        ref.addEventListener("upload-response", (event) => {
          const {
            detail: {
              xhr: { responseText },
            },
          } = event;
          const response = JSON.parse(responseText);
          const media = response[0];

          if (isFunction(onChange)) {
            onChange(media);
          }
        });
      }}
    />
  );
}

export default Upload;
