import { gql } from "@apollo/client";
import { pick } from "lodash";
import userDetailsFragment from "./user-details-fragment";

export const formatUpdateUserQueryData = (source) => {
  // pick generic fields
  const result = pick(source, ["email", "password", "blocked"]);

  // force email as username
  result.username = result.email;

  // role
  if ("role" in source) {
    result.role = source.role.id;
  }

  // password null check
  if ("password" in source) {
    if (result.password === "") {
      result.password = null;
    }
  }

  // shop type access
  if ("shop_type_access" in source) {
    result.shop_type_access = source.shop_type_access.map(
      (shopType) => shopType.id
    );
  }

  return result;
};

export default gql`
  mutation($id: ID!, $data: editUserInput) {
    updateUser(input: { where: { id: $id }, data: $data }) {
      user {
        ...UserDetails
      }
    }
  }

  ${userDetailsFragment}
`;
