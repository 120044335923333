import React from "react";
import {
  Item,
} from "react-vaadin-components";
import Icons from "@vaadin/vaadin-icons";

function LoadingAnimation({}) {
  return (
    <Item id="loading-animation" className="sis-container sis-center">
      <div>
        <strong><iron-icon class="sis-icon--spinning" icon="vaadin:refresh"></iron-icon></strong>
      </div>
    </Item>
  );
}

export default LoadingAnimation;
