import { gql } from "@apollo/client";
import { get, cloneDeep, isObject, map } from "lodash";
import shopDetailsFragment from "./shop-details-fragment";

const NON_UPDATABLE_FIELDS = ["id"];
const NON_UPDATABLE_RECURSICE = [
  "__typename",
  "created_at",
  "updated_at",
  "type",
];

const deleteRecursive = (obj) => {
  NON_UPDATABLE_RECURSICE.forEach((field) => {
    if (obj[field]) delete obj[field];
  });
  Object.keys(obj).forEach((key) => {
    if (isObject(obj[key])) deleteRecursive(obj[key]);
  });
};

export const formatUpdateShopQueryData = (data) => {
  const clonedData = cloneDeep(data);
  NON_UPDATABLE_FIELDS.forEach((field) => {
    if (clonedData[field]) delete clonedData[field];
  });
  deleteRecursive(clonedData);

  clonedData.image = get(clonedData, "image.id", null);
  clonedData.services = map(clonedData.services, (s) => {
    const sc = cloneDeep(s);
    sc.image = get(sc, "image.id", null);
    NON_UPDATABLE_FIELDS.forEach((field) => {
      if (sc[field]) delete sc[field];
    });
    return sc;
  });
  return clonedData;
};

export const updateShopQuery = gql`
  mutation($id: ID!, $data: editShopInput) {
    updateShop(input: { where: { id: $id }, data: $data }) {
      shop {
        ...ShopDetails
      }
    }
  }

  ${shopDetailsFragment}
`;

export default updateShopQuery;
