import {
  registerStyles,
  css,
} from "@vaadin/vaadin-themable-mixin/register-styles.js";

// Vaadin menu-bar main icon + text styles
export default registerStyles(
  "vaadin-menu-bar",
  css`
    vaadin-menu-bar-button #sis-avatar-menu-text-main,
    vaadin-menu-bar-button vaadin-context-menu-item {
      color: #9b0ae3;
      font-size: 0.75em;
    }
    vaadin-menu-bar-button iron-icon {
      color: #9b0ae3;
      width: 24px;
    }
  `
);
