import React, { useRef, useEffect } from "react";
import { isFunction } from "lodash";
import "@vaadin/vaadin-menu-bar";

// Example
// import MenuBar from "../MenuBar";
//
// const items = [
//   { text: "Test1", callback: () => console.log("Test1 clicked") },
//   { text: "Test2", callback: () => console.log("Test2 clicked") },
// ];
//
// <MenuBar items={items} />;

function Heading({ items = [] }) {
  const element = useRef();

  useEffect(() => {
    if (!element.current) {
      return;
    }

    // set items
    element.current.items = items;

    // set event listener
    const eventHandler = (event) => {
      // get item
      const item = event.detail.value;

      // check for callback
      if (isFunction(item.callback)) {
        item.callback(item);
      }
    };

    element.current.addEventListener("item-selected", eventHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      // clear event handler
      element.current.removeEventListener("item-selected", eventHandler);
    };
  }, [element.current]);

  return <vaadin-menu-bar ref={element} />;
}

export default Heading;
