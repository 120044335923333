import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useAuthToken, useRemoveAuthToken } from "./auth";
import { notificationService } from "../config/notifications";

// setup link
const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
});

const createAuthLink = (authToken) => {
  return new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    if (authToken) {
      operation.setContext(({ headers }) => ({
        headers: {
          Authorization: `Bearer ${authToken}`,
          ...headers,
        },
      }));
    }

    return forward(operation);
  });
};

const createOnErrorLink = (removeAuthToken) => {
  return onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      // eslint-disable-next-line no-restricted-syntax
      for (const { message } of graphQLErrors) {
        if (message === "User Not Found") {
          // show notification
          notificationService.error({
            message: "error.title.invalidAuthToken",
          });

          return removeAuthToken();
        }
      }
    }
  });
};

// setup cache
const cache = new InMemoryCache();

// exports
// eslint-disable-next-line import/prefer-default-export
export const useApolloClient = () => {
  const [authToken] = useAuthToken();
  const removeAuthToken = useRemoveAuthToken();

  return new ApolloClient({
    cache,
    link: from([
      createAuthLink(authToken),
      createOnErrorLink(removeAuthToken),
      httpLink,
    ]),
  });
};
