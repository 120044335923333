import { Ability } from "@casl/ability";
import { sortBy } from "lodash";
import React from "react";
import { Item, ListBox } from "react-vaadin-components";

function ShopTypeSelector({ shopTypes, accessRules, onSelect }) {
  const ability = new Ability(accessRules);
  const items = sortBy(shopTypes, "displayName");

  return (
    <ListBox onSelectedChanged={(event) => onSelect(items[event.detail.value])}>
      {items.map((type) => (
        <Item
          key={type.id}
          disabled={!ability.can("create", "Shop", type.name)}
        >
          {type.displayName}
        </Item>
      ))}
    </ListBox>
  );
}

export default ShopTypeSelector;
