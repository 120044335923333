import { Ability } from "@casl/ability";
import { HorizontalLayout } from "react-vaadin-components";
import { get } from "lodash";
import { withTranslation } from "react-i18next";
import React, { useState } from "react";
import * as yup from "yup";

import ShopLocation from "../ShopLocation";
import ShopLocationMap from "../ShopLocation/Map";
import ShopServices from "../ShopServices";
import ShopInfo from "../ShopInfo";
import ShopImage from "../Image";
import OpeningHours from "../OpeningHours";
import OpeningHourExceptions from "../OpeningHourExceptions";

// import { Can, AbilityContext } from "./Can";

function ShopForm({ shop, accessRules, options, onChange, t, isLoading }) {
  const ability = new Ability(accessRules);
  const canUpdateOpeningHours = ability.can("update", "Shop", "openingHours");
  const canUpdateLocation = ability.can("update", "Shop", "location");
  // this doesn't have own rights yet. Should it?
  const canUpdateExceptions = ability.can("update", "Shop", "openingHours");
  const canUpdateServices = ability.can("update", "Shop", "services");

  // resolve publish state
  let publishState = "sis-container--warning";
  let publishTitle = "shop.publish.isNotPublished";
  let publishProblems = null;
  if (shop.published) {
    // shop should be published, check validity
    const publishedSchema = yup.object().shape({
      name: yup.string().required(t("shop.publish.nameMissing")),
      published: yup.boolean().oneOf([true]),
      location: yup.object().shape({
        coordinatesLat: yup
          .number(t("location.coordinatesLat.label"))
          .required(t("location.coordinatesLat.label"))
          .label(t("location.coordinatesLat.label")),
        coordinatesLng: yup
          .number(t("location.coordinatesLat.label"))
          .required(t("location.coordinatesLat.label"))
          .label(t("location.coordinatesLng.label")),
      }),
    });

    try {
      publishedSchema.validateSync(shop, { abortEarly: false });
      publishState = "sis-container--success";
      publishTitle = "shop.publish.isPublished";
    } catch (error) {
      publishState = "sis-container--error";
      publishTitle = "shop.publish.isCannotPublished";
      publishProblems = error.errors.map((e, i) => (
        <div key={`error-${i}`}>
          <small key={`problem-${i}`}>{e}</small>
        </div>
      ));
    }
  }

  return (
    <>
      <div className={`sis-container ${publishState}`}>
        <strong>{t(publishTitle)}</strong>
        {publishProblems}
      </div>

      <div className="sis-container">
        <div className="sis-h-layout--fwc">
          <div className="row">
            <div className="column-2">
              <ShopInfo
                loading={isLoading}
                shop={shop}
                accessRules={accessRules}
                onChange={onChange}
              />
            </div>
            <div className="column-2 additional-div">
              <ShopImage
                image={shop.image}
                updateImage={(image) => onChange("image", image)}
                readOnly={!ability.can("update", "Shop", "image")}
              />
            </div>
          </div>
        </div>
      </div>

      <h4 className="sis-container__title">{t("shop.location.title")}</h4>
      <div className="sis-container">
        <div className="sis-h-layout--fwc">
          <div className="row">
            <div className="column-2">
              <ShopLocation
                loading={isLoading}
                shop={shop}
                accessRules={accessRules}
                onChange={onChange}
              />
            </div>
            <div className="column-2 additional-div map-fix-div-wrapper">
              <ShopLocationMap
                shop={shop}
                onChange={onChange}
                readOnly={!canUpdateLocation}
              />
            </div>
          </div>
        </div>
      </div>

      <h4 className="sis-container__title">{t("shop.openinghours.title")}</h4>
      <div className="sis-container">
        <div className="sis-h-layout--fwc">
          <div className="row xl-breakpoint">
            <div className="column-2">
              <OpeningHours
                loading={isLoading}
                weekdays={get(shop, "openingHours.weekdays", {})}
                accessRules={accessRules}
                readOnly={!canUpdateOpeningHours}
                onChange={(weekdays) => {
                  onChange("openingHours.weekdays", weekdays);
                }}
              />
            </div>
            <div className="column-2 additional-div">
              <OpeningHourExceptions
                exceptions={get(shop, "openingHours.exceptions")}
                readOnly={!canUpdateExceptions}
                onChange={(exceptions) => {
                  onChange("openingHours.exceptions", exceptions);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <h4 className="sis-container__title">{t("shop.services.title")}</h4>
      <div className="sis-container">
        <div className="sis-h-layout--fwc sis-h-layout--fwc--break-xl sis-shop-details__opening-hours">
          <ShopServices
            loading={isLoading}
            services={get(shop, "services", [])}
            readOnly={!canUpdateServices}
            onChange={(services) => {
              onChange("services", services);
            }}
          />
        </div>
      </div>
    </>
  );
}

export default withTranslation()(ShopForm);
