import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { useParams, useHistory } from "react-router";
import { withTranslation } from "react-i18next";

import Query from "../../components/Query";
import ShopContainer from "./container";
import {
  updateShopQuery,
  formatUpdateShopQueryData,
} from "./queries/update-query";
import { deleteShopQuery } from "./queries/delete-query";
import { fetchShopQuery } from "./queries/fetch-query";
import { useNotificationService } from "../../services/notification";

function Shop({ t }) {
  const history = useHistory();
  const { id } = useParams();
  const notifications = useNotificationService();
  const [queryError, setQueryError] = useState(null);

  const [updateShop] = useMutation(updateShopQuery, {
    onCompleted: () => {
      notifications.success({
        content: <p>{t("shop.update.success.msg")}</p>,
      });
    },
    onError: (error) => {
      notifications.error({
        content: (
          <p>
            <b>{t("common.error.title")} </b>
            <br />
            {t("shop.update.error.msg")}
          </p>
        ),
      });

      setQueryError(true);
    },
  });

  const [deleteShop] = useMutation(deleteShopQuery, {
    onCompleted: () => {
      notifications.success({ content: <p>{t("shop.delete.success.msg")}</p> });
      history.replace("/shops");
    },
    onError: () => {
      notifications.error({
        content: (
          <p>
            <b>{t("common.error.title")}</b>
            <br />
            {t("shop.delete.error.msg")}
          </p>
        ),
      });
      setQueryError(true);
    },
  });

  const onDelete = () => {
    setQueryError(false);
    deleteShop({
      variables: { id },
    });
  };
  const onUpdate = (shop) => {
    setQueryError(false);
    const data = formatUpdateShopQueryData(shop);
    updateShop({
      variables: { data, id },
    });
  };

  return (
    <Query query={fetchShopQuery} variables={{ id }}>
      {({ data: { shop, shopAccessRules } }) => {
        return (
          <ShopContainer
            shop={shop}
            shopAccessRules={shopAccessRules}
            onUpdate={onUpdate}
            onDelete={onDelete}
            error={queryError}
          />
        );
      }}
    </Query>
  );
}

export default withTranslation()(Shop);
