import React, { useRef } from "react";
import { get, cloneDeep } from "lodash";

import OpeningHours from "../OpeningHours";
import OpeningHourExceptions from "../OpeningHourExceptions";

function ServiceOpeningHours({ openingHours, readOnly, onChange, isLoading }) {
  const dataRef = useRef();
  dataRef.current = cloneDeep(openingHours) || {};
  return (
    <div className="sis-h-layout--fwc sis-shop-details__opening-hours">
      <div className="row xl-breakpoint">
        <div className="column-2">
          <OpeningHours
            loading={isLoading}
            weekdays={get(dataRef.current, "weekdays", {})}
            readOnly={readOnly}
            onChange={(weekdays) => {
              dataRef.current.weekdays = weekdays;
              onChange(dataRef.current);
            }}
          />
        </div>
        <div className="column-2 additional-div">
          <OpeningHourExceptions
            exceptions={get(dataRef.current, "exceptions")}
            readOnly={readOnly}
            onChange={(exceptions) => {
              dataRef.current.exceptions = exceptions;
              onChange(dataRef.current);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ServiceOpeningHours;
