import React, { useRef, useState } from "react";
import { get, isEmpty, orderBy } from "lodash";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Grid, GridColumn, GridSortColumn } from "react-vaadin-components";
import { Ability } from "@casl/ability";

import { useConfirmService } from "../../services/confirm";
import { isMobile } from "../../helpers/Functions";

function RenderServiceTypeList({ item }) {
  const list = item.services;
  const serviceTypes = list
    .filter((item) => item.published)
    .map((item) => item.type.displayName)
    .join(", ");
  return serviceTypes; // return the serviceTypes of the shop
}

function RenderImageCheck({ item }) {
  if (item.image) {
    return (
      <iron-icon
        id="icon-success"
        className="sis-icon-user--actived"
        icon="vaadin:check-circle"
      />
    );
  }

  return (
    <iron-icon
      id="icon-error"
      className="sis-icon-user--disabled"
      icon="vaadin:close-circle"
    />
  );
}

function RenderOpeningHoursCheck({ item }) {
  // Check if item has opening hours
  // then check if it has weekdays
  // and then check if the shop is open on some weekday

  const hasOpeningHours = isEmpty(get(item, "openingHours.weekdays"));
  const testItem = item;
  const oh = testItem.openingHours;

  // Check if all weekday ... IsOpens are null. If null show icon-error (closed)
  let checkAll = true;
  if (oh) {
    const weekd = testItem.openingHours.weekdays;

    for (const property in weekd) {
      if (`${property}` != "__typename") {
        if (`${weekd[property]}` === null || `${weekd[property]}` == "null")
          checkAll = false;
      }
    }
  }

  if (!hasOpeningHours && checkAll) {
    return (
      <iron-icon
        id="icon-success"
        className="sis-icon-user--actived"
        icon="vaadin:check-circle"
      />
    );
  }

  return (
    <iron-icon
      id="icon-error"
      className="sis-icon-user--disabled"
      icon="vaadin:close-circle"
    />
  );
}

function RenderPublishedCheck({ item }) {
  // Check if item has opening hours
  // then check if it has weekdays
  // and then check if the shop is open on some weekday
  const isPublished = item.published;

  if (isPublished) {
    return (
      <iron-icon
        id="icon-success"
        className="sis-icon-user--actived"
        icon="vaadin:eye"
        style={{ width: "12px", height: "12px" }}
      />
    );
  }

  return (
    <iron-icon
      id="icon-error"
      className="sis-icon-user--disabled"
      icon="vaadin:eye-slash"
      style={{ width: "12px", height: "12px" }}
    />
  );
}

function RenderShopLocality({ item }) {
  const isRemote = item.type.id === "1" || item.type.id === "2";

  if (isRemote) {
    return (
      <iron-icon
        id="icon-id-locality"
        className="sis-icon-locality"
        icon="vaadin:cloud-o"
        title="Tuotu"
      />
    );
  }

  return (
    <iron-icon
      id="icon-id-locality"
      className="sis-icon-locality"
      icon="vaadin:database"
      title="Paikallinen"
    />
  );
}

function ShopList({ shops, onDelete, onOpen, options, accessRules, t }) {
  const ability = new Ability(accessRules);

  const confirm = useConfirmService();
  const history = useHistory();
  const grid = useRef();
  const hiddenFields = isMobile();
  const [sortName, setSortName] = useState("created_at"); // send this to new shop sort!
  const [direction, setDirection] = useState("asc"); // send this to new shop sort!

  const limitResultsTo = 25;
  const [limit, setLimit] = useState(limitResultsTo);

  shops = orderBy(shops, sortName, direction);
  shops = shops.slice(0, limit);

  /// END OF SHOP SORTING

  function onShowAll() {
    setLimit(undefined);
  }

  function CreateShopListButtonRenderer(onOpen, onDelete) {
    return ({ item }) => {
      const deleteAbility = ability.can("delete", "Shop", item.type.name);
      const editAbility = ability.can("update", "Shop", item.type.name);
      return (
        <>
          {editAbility ? (
            <iron-icon
              id="icon-id-edit"
              className="sis-icon-edit"
              value={item.id}
              onClick={(e) => onOpen(item)}
              icon="vaadin:pencil"
            />
          ) : null}

          {deleteAbility ? (
            <iron-icon
              id="icon-id-delete"
              className="sis-icon-delete"
              onClick={() => onDelete(item)}
              icon="vaadin:trash"
            />
          ) : null}
        </>
      );
    };
  }

  const counts = options.counts;
  const current = options.counts.current;
  const total = options.counts.total;
  const typeInUse = options.counts.typeInUse;
  const serviceInUse = options.counts.serviceInUse;

  // console.log(
  //   "counts:" +
  //     counts +
  //     " current:" +
  //     current +
  //     " total:" +
  //     total +
  //     " typeInUse:" +
  //     typeInUse +
  //     " servInUse:" +
  //     serviceInUse
  // );

  function createShowAllElement(current) {
    return (
      <a className="primary" onClick={onShowAll}>
        {t("common.text.showAll")} ({current})
      </a>
    );
  }

  function createResultBelowOrNoLimitElement(total) {
    return (
      <span>
        {t("shops.field.counts.typeInUse.showRest", { current: total })}
      </span>
    );
  }

  function createShowingAmountOfLimit(limit, showAllCount = shops.length) {
    return (
      <span>
        {t("shops.field.counts.typeInUse", { limit: limit })}
        &nbsp;
        {createShowAllElement(showAllCount)}
      </span>
    );
  }

  function createDefaultResultElement(limit, total) {
    return (
      <span>
        {t("shops.field.counts.notall", { limit: limit })}
        &nbsp;
        {createShowAllElement(total)}
        <span className="sis-smallText">
          {t("shops.field.counts.showAll.warning")}
        </span>
      </span>
    );
  }

  function createResultInfo() {
    if (!limit) {
      if (serviceInUse || typeInUse) {
        return createResultBelowOrNoLimitElement(shops.length);
      }

      return createResultBelowOrNoLimitElement(current);
    }
    if (serviceInUse || typeInUse) {
      // ServiceInUse somehow does not work like type... that's why this fix
      if (serviceInUse) {
        if (limit > shops.length) {
          return createResultBelowOrNoLimitElement(shops.length);
        }
        return createShowingAmountOfLimit(limit, current);
      }
      // if typeinuse and has fewer items than the limit, show just the "found x amount of results"
      if (limit > current) {
        return createResultBelowOrNoLimitElement(current);
      }

      return createShowingAmountOfLimit(limit, current);
    }

    if (counts && current !== total) {
      if (current < total) {
        if (current < limit) {
          return createResultBelowOrNoLimitElement(current);
        }
        return createDefaultResultElement(limit, current);
      }
      return createShowingAmountOfLimit(limit, current);
    }

    return createDefaultResultElement(limit, total);
  }

  const resultInfo = createResultInfo();

  return (
    <>
      {resultInfo}

      <Grid
        id="Shops-list"
        className="sis-container"
        items={shops}
        heightByRows={true}
        ref={grid}
        onClick={(e) => {
          if (e.target.icon !== undefined) {
            // Let's ignore icons (e.g. edit / delete)
            return;
          }

          const { item } = grid.current._element.getEventContext(e);
          if (item) {
            history.push(`/shops/${item.id}`);
          } else {
            // sorting if
            if (grid.current._element.getEventContext(e)) {
              if (grid.current._element.getEventContext(e).column) {
                if (
                  grid.current._element.getEventContext(e).column.direction !==
                  null
                ) {
                  setSortName(
                    grid.current._element.getEventContext(e).column.path
                  );
                  setDirection(
                    grid.current._element.getEventContext(e).column.direction
                  );
                } else {
                  setSortName("created_at");
                  setDirection("asc");
                }
              }
            }
          }
        }}
      >
        <GridSortColumn
          path="published"
          header="   "
          renderer={RenderPublishedCheck}
          textAlign="center"
          resizable
          width="1.7em"
          direction={
            sortName === "published"
              ? direction !== null
                ? direction
                : null
              : null
          }
        />
        <GridSortColumn
          path="name"
          header={t("shop.field.name")}
          resizable
          width="14em"
          direction={
            sortName === "name" ? (direction !== null ? direction : null) : null
          }
        />
        <GridSortColumn
          path="type.displayName"
          header={t("shop.field.type")}
          resizable
          direction={
            sortName === "type.displayName"
              ? direction !== null
                ? direction
                : null
              : null
          }
        />
        <GridSortColumn
          path="location.street"
          header={t("shop.field.street")}
          resizable
          direction={
            sortName === "location.street"
              ? direction !== null
                ? direction
                : null
              : null
          }
        />
        <GridSortColumn
          path="location.postalCode"
          header={t("shop.field.postalCode")}
          direction={
            sortName === "location.postalCode"
              ? direction !== null
                ? direction
                : null
              : null
          }
        />
        <GridSortColumn
          path="location.city"
          header={t("shop.field.city")}
          resizable
          direction={
            sortName === "location.city"
              ? direction !== null
                ? direction
                : null
              : null
          }
        />
        <GridSortColumn
          path="openingHours"
          header={t("shop.field.hasOpeningHours")}
          renderer={RenderOpeningHoursCheck}
          hidden={hiddenFields}
          textAlign="center"
          autoWidth
          width="35px"
          direction={
            sortName === "openingHours"
              ? direction !== null
                ? direction
                : null
              : null
          }
        />
        <GridSortColumn
          path="services"
          header={t("shop.field.serviceTypes")}
          renderer={RenderServiceTypeList}
          resizable
          direction={
            sortName === "services"
              ? direction !== null
                ? direction
                : null
              : null
          }
        />
        <GridSortColumn
          path="type.id"
          header={t("shop.field.locality")}
          renderer={RenderShopLocality}
          textAlign="center"
          hidden={hiddenFields}
          width="6em"
          direction={
            sortName === "type.id"
              ? direction !== null
                ? direction
                : null
              : null
          }
        />
        <GridColumn
          width="3.8em"
          // hidden={!ability.can("delete", "Shop")}
          renderer={CreateShopListButtonRenderer(onOpen, (item) => {
            confirm.show({
              buttonOk: t("common.button.ok"),
              buttonCancel: t("common.button.cancel"),
              onOk: () => {
                onDelete(item);
              },
              content: (
                <>
                  <p>{t("shop.delete.confirm.msg")}</p>
                  <h4>{item.name}</h4>
                </>
              ),
            });
          })}
        />
      </Grid>
    </>
  );
}

export default withTranslation()(ShopList);
