import * as yup from "yup";

export function createLocale(t) {
  return {
    mixed: {
      default: t("yup.validate.invalid"),
      required: t("yup.validate.required"),
      notType: function notType(_ref) {
        var path = _ref.path,
          type = _ref.type,
          value = _ref.value,
          originalValue = _ref.originalValue;
        var isCast = originalValue != null && originalValue !== value;
        var msg =
          path + t("yup.validate.mustbea") + " " + t("common.words." + type);

        if (value === null) {
          msg += "\n tämä kenttä ei saa olla tyhjä!";
        }

        return msg;
      },
    },
    string: {
      length: t("yup.validate.string.length"),
      min: t("yup.validate.string.min"),
      email: t("yup.validate.email"),
      url: t("yup.validate.url"),
    },
    number: {
      min: t("yup.validate.number.min"),
      max: t("yup.validate.number.max"),
      lessThan: "${path} must be less than ${less}",
      moreThan: "${path} must be greater than ${more}",
      notEqual: "${path} must be not equal to ${notEqual}",
      positive: "${path} must be a positive number",
      negative: "${path} must be a negative number",
      integer: "${path} must be an integer",
    },
    date: {
      min: "${path} field must be later than ${min}",
      max: "${path} field must be at earlier than ${max}",
    },
  };
}

export default function ({ t, rules }) {
  const result = new yup();
  result.setLocale(createLocale(t));
  if (rules) {
    result.object().shape(rules);
  }
  return result;
}
